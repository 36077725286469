import React, {ChangeEvent, FunctionComponent, useState} from "react";
import styles from './ContactForm.module.scss'
import {contactMailUrl, emailValidation, postRequest} from "../../api/util";
import {useIntl} from "react-intl";
import {ConsentCheck} from "../ConsentCheck/ConsentCheck";
import {HoneyPot} from "../HoneyPot/HoneyPot";
import {Button, ButtonType, TextInput, TextArea} from "@dis/colors-components";
import {ROUTES} from "../../utils/routes";
import {useNavigate} from "react-router-dom";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

export interface ContactFormProps {
  showHeading?: boolean;
  showIntro?: boolean;
  navigateAfterResponseOk: boolean
  onePathSegmentUp?: boolean;
}

export const ContactForm: FunctionComponent<ContactFormProps> = ({
                                                                   showHeading = true,
                                                                   showIntro = true,
                                                                   navigateAfterResponseOk,
                                                                   onePathSegmentUp = false
                                                                 }) => {

  const intl = useIntl();
  const navigate = useNavigate();

  const [showSentResult, setShowSentResult] = useState(false);

  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");

  const [contactNameError, setContactNameError] = useState(false);
  const [contactNameEmptyError, setContactNameEmptyError] = useState(false);
  const [contactEmailError, setContactEmailError] = useState(false);
  const [contactEmailEmptyError, setContactEmailEmptyError] = useState(false);
  const [contactMessageError, setContactMessageError] = useState(false);
  const [contactMessageEmptyError, setContactMessageEmptyError] = useState(false);

  const [contactConsentChecked, setContactContactConsentChecked] = useState(false);
  const [contactHoneypotEmpty, setContactHoneypotEmpty] = useState(true);

  const onSend = async () => {
    if (handleValidation()) {
      try {
        const finalContactMailUrl = onePathSegmentUp ? "../" + contactMailUrl : contactMailUrl;
        console.log("finalContactMailUrl: ", finalContactMailUrl)
        let postResponse = await postRequest(finalContactMailUrl, {
          body: JSON.stringify({
            form: "contact",
            name: contactName,
            address: contactEmail,
            message: contactMessage
          })
        });
        if (postResponse.ok) {
          if (navigateAfterResponseOk) {
            navigate(ROUTES.LEGAL_NOTICE_PRIVACY_BAR.CONTACT_SENT);
          } else {
            setShowSentResult(true);
          }
        } else {
          console.log("Error while sending contact mail: ", postResponse);
        }
      } catch (error) {
        console.error("Error while sending contact mail.", error);
      }
    }
  };

  const lettersSymbolsValidation = (value: string) => value.match(/^[\u0021-\u007E\s\u00C0-\u017F]+$/);

  /* checks before POST */
  const handleValidation = () => {
    let contactFormIsValid = true;
    setContactEmailError(false);
    setContactNameError(false);
    setContactMessageEmptyError(false);

    // Name
    if (!contactName || contactName === "") {
      contactFormIsValid = false;
      setContactNameEmptyError(true);
    }

    // E-Mail
    if (!contactEmail || contactEmail === "") {
      contactFormIsValid = false;
      setContactEmailEmptyError(true);
    } else if (typeof contactEmail !== "undefined") {
      if (!emailValidation(contactEmail)) {
        contactFormIsValid = false;
        setContactEmailError(true);
      }
    }

    // Message
    if (!contactMessage || contactMessage === "") {
      contactFormIsValid = false;
      setContactMessageEmptyError(true);
    }

    return contactFormIsValid;
  };

  const onContactCheckboxClicked = () => {
    setContactContactConsentChecked(!contactConsentChecked);
  };

  const handleContactHoneypot = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setContactHoneypotEmpty(false);
    }
  };


  const saveValue = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, input: string) => {
    e.preventDefault();
    const value = e.target.value;
    /* immediate checks! */
    switch (input) {
      case "contactName":
        setContactNameError(!lettersSymbolsValidation(value));
        setContactNameEmptyError(!value);
        setContactName(value);
        break;
      case "contactEmail":
        setContactEmailError(false);
        setContactEmailEmptyError(!value);
        setContactEmail(value);
        break;
      case "contactMessage":
        setContactMessageError(!lettersSymbolsValidation(value));
        setContactMessageEmptyError(!value);
        setContactMessage(value);
        break;
    }
  };

  const errorMessage = (error: boolean, messageId: string, empty?: boolean) => {
    if (empty) {
      return intl.formatMessage({id: "InputField.empty"});
    }
    if (error) {
      return intl.formatMessage({id: messageId})
    } else {
      return ""
    }
  };

  return (
    <div>
      <div className={styles.ContactPage}>
        <div className={styles.form}>
          {showHeading && <h2 className={styles.header}>{intl.formatMessage({id: "ContactPage.heading"})}</h2>}
          {showIntro && <p className={styles.introText}>{intl.formatMessage({id: "ContactPage.introText"})}</p>}
          <div className={styles.inputFields}>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={contactName}
                           label={intl.formatMessage({id: "ContactPage.name"})}
                           onChange={e => saveValue(e, "contactName")}
                           requiredMessage={intl.formatMessage({id: "InputField.empty"})}
                           errorMessage={errorMessage(contactNameError, "ContactPage.letters", contactNameEmptyError)}
                />
              </div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.withError}>
                <TextInput placeholder={""}
                           value={contactEmail}
                           label={intl.formatMessage({id: "ContactPage.mail"})}
                           onChange={e => saveValue(e, "contactEmail")}
                           errorMessage={errorMessage(contactEmailError, "ContactPage.validMail", contactEmailEmptyError)}
                           requiredMessage={intl.formatMessage({id: "InputField.empty"})}
                />
              </div>
            </div>
            <div className={styles.textArea}>
              <div className={styles.withError}>
                <TextArea label={intl.formatMessage({id: "ContactPage.message"})}
                          placeholder={intl.formatMessage({id: "ContactPage.type"}) + "..."}
                          onChange={e => saveValue(e, "contactMessage")}
                          maxCharacterLimit={1000000} showCount={false}
                          requiredMessage={intl.formatMessage({id: "InputField.empty"})}
                          errorMessage={errorMessage(contactMessageError, "Input.invalidCharacters", contactMessageEmptyError)}

                />
              </div>
            </div>
            <ConsentCheck consentChecked={contactConsentChecked} handleChange={onContactCheckboxClicked}/>
            <HoneyPot handleChange={(e) => handleContactHoneypot(e)}/>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.button}>
              {showSentResult ?
                <MarkEmailReadIcon style={{"fontSize": "51px"}}/>
                : <Button label={intl.formatMessage({id: "ContactPage.button"})}
                          onClick={onSend} type={ButtonType.PRIMARY}
                          disabled={!contactConsentChecked && contactHoneypotEmpty}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
