import React, {FunctionComponent, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, ButtonType, IconPosition} from "@dis/colors-components";
import {JobmatchStepProps} from "./JobmatchPage";
import {useDispatch, useSelector} from 'react-redux';
import {selectChosenJob, setCurrentStep} from "../../redux/jobmatchSlice";
import styles from './JobmatchPage.module.scss';
import {ROUTES} from "../../utils/routes";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {ContactForm} from "../../components/ContactForm/ContactForm";

const JobmatchStep6: FunctionComponent<JobmatchStepProps> = ({step, prev, next}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentStep(step))
  }, [step, dispatch]);

  const chosenJob = useSelector(selectChosenJob);
  const link = "https://web.arbeitsagentur.de/berufenet/ergebnisseite?suchwoerter=" + chosenJob.title.replace(":in", "")
    .replace("IT-Projektleiter", "IT Projekt")
    .replace("IT-Systemadministrator", "Systemadministrator");

  return (
    <>
      <h3>Auf einen Blick</h3>
      <h4>Alle Informationen zum Beruf der <strong>{chosenJob.title}</strong> findest du hier auf einen Blick.</h4>
      <div className={styles.buttons}>
        <Button label={"Zusammenfassung herunterladen"}
                type={ButtonType.SECONDARY}
                icon={<FileDownloadOutlinedIcon/>}
                iconPosition={IconPosition.RIGHT}
                onClick={() => window.open("../" + chosenJob.pdf)}
        />
        <Button label={"Mehr Informationen auf BERUFENET finden"}
                type={ButtonType.SECONDARY}
                onClick={() => window.open(link, "_blank", "noreferrer, noopener")}
        />
      </div>
      <div className={styles.stepContent}>
        <div className={styles.person}>
          {chosenJob.mentor.photo ?
            <img alt={"mentorPhoto"} src={chosenJob.mentor.photo}/>
            : <AccountBoxIcon className={styles.avatar}/>
          }
          <h4>{chosenJob.mentor.name}</h4>
          <p><b>{chosenJob.mentor.profession}</b></p>
          <p className={styles.considerLinebreaks}>{chosenJob.mentor.qualification}</p>
          <p><b>{chosenJob.mentor.location}</b></p>
        </div>
        <div className={styles.contactForm}>
          <h4>Noch Fragen? Stelle Sie hier einer <strong>{chosenJob.title}</strong>.</h4>
          <ContactForm showHeading={false} showIntro={false} navigateAfterResponseOk={false} onePathSegmentUp/>
        </div>
      </div>
      <div className={styles.buttons}>
        {prev && <Button label={"Zurück zum Lernangebot des Berufs"}
                         onClick={() => navigate(prev)}
                         type={ButtonType.SECONDARY}/>}
        {next && <Button label={"Abschließen und zur Startseite"}
                         onClick={() => navigate(ROUTES.DASHBOARD.BASE)}/>}
      </div>
    </>
  )
}

export default JobmatchStep6;
