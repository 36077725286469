import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const sliceName = 'language';

// Interface defines structure of Language State
interface LanguageState {
  selectedLanguage: string,
}

const initialState: LanguageState = {
  selectedLanguage: localStorage.getItem('currentLanguage') || "DEU",
}

// Redux slice handling actions on the Language slice
const languageSlice = createSlice({
  'name': sliceName,
  'initialState': initialState,
  'reducers': {
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    }
  }
})

export const {setLanguage} = languageSlice.actions;

// Selector to get the languages out of the Language State
// or use in Component `useSelector((state) => state.counter.value)`
export const selectLanguage = (state: RootState) => state.language.selectedLanguage;

// to be used by redux storage to set up the language slice
export const languageReducer = languageSlice.reducer;
