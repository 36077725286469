import React, {FunctionComponent, useEffect} from 'react';
import {To, useNavigate} from "react-router-dom";
import {Button, ButtonType} from "@dis/colors-components";
import {JobmatchStepProps} from "./JobmatchPage";
import {useDispatch, useSelector} from 'react-redux';
import {selectChosenJob, setCurrentStep} from "../../redux/jobmatchSlice";
import styles from './JobmatchPage.module.scss';
import {selectFormatConfigurationFromLesson, selectLessonByName} from "../../redux/lessonsSlice";
import {FormatConfigurationWithMetaData, ShallowEntity} from "../../utils/types/api";
import {getFormatPreviewSource} from "../../utils/formatHelper";

const JobmatchStep5: FunctionComponent<JobmatchStepProps> = ({step, prev, next}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentStep(step))
  }, [step, dispatch]);

  const chosenJob = useSelector(selectChosenJob);
  const lessonId = useSelector(selectLessonByName(chosenJob.lesson))[0].id!;

  const formatConfiguration = useSelector(
    selectFormatConfigurationFromLesson(lessonId)
  );

  function displayFormat(
    formatConfig: ShallowEntity<FormatConfigurationWithMetaData>
  ) {
    return (
      <div className={styles.internalCourse}>
        <iframe
          src={"../" + getFormatPreviewSource(formatConfig)}
          title={"internalCourse"}
        />
      </div>
    );
  }

  const onNextClick = (next: To) => {
    navigate(next);
  };

  return (
    <>
      <h4>Wie sieht der Arbeitsalltag einer <strong>{chosenJob.title}</strong> aus? Klicke auf „Start“, um mehr zu erfahren.</h4>
      <div className={styles.stepContent}>
          {formatConfiguration && displayFormat(formatConfiguration)}
      </div>
      <div className={styles.buttons}>
        {prev && <Button label={"Zurück zum Video"}
                         onClick={() => navigate(prev)}
                         type={ButtonType.SECONDARY}/>}
        {next && <Button label={"Alle Informationen auf einen Blick"}
                         onClick={() => onNextClick(next)}
                         />}
      </div>
    </>
  )
}

export default JobmatchStep5;
