import styles from './JacketCustomizations.module.scss';
import {CustomizationOptionPreview, Customizations, CustomizationsStyles} from "./Customizations";


export enum JacketCustomizationsIds {
    DEFAULT = "jacket_0",
    TYPE_1 = "jacket_1",
    TYPE_2 = "jacket_2",
}

const JacketCustomizationPreviews: CustomizationOptionPreview<JacketCustomizationsIds>[] = [
    {id: JacketCustomizationsIds.DEFAULT, previewColor1: "#b3e6bc", previewColor2: "#5fb5c1"},
    {id: JacketCustomizationsIds.TYPE_1, previewColor1: "#e3e5e4", previewColor2: "#315426"},
    {id: JacketCustomizationsIds.TYPE_2, previewColor1: "#010202", previewColor2: "#ebe324"}
]

const JacketStyles: CustomizationsStyles<JacketCustomizationsIds> = {
    [JacketCustomizationsIds.DEFAULT]: styles.jacket_0,
    [JacketCustomizationsIds.TYPE_1]: styles.jacket_1,
    [JacketCustomizationsIds.TYPE_2]: styles.jacket_2,
}

const JacketCustomizations: Customizations<JacketCustomizationsIds> = {
    previews: JacketCustomizationPreviews,
    styles: JacketStyles,
}

export default JacketCustomizations;
