import React, {FunctionComponent} from 'react';
import styles from './MiaButton.module.scss';
import {MiaCustomization} from "./MiaCustomization/MiaCustomization";
import {MiaSettings} from "../../api/userApi";
import {MiaPose} from "./MiaCustomization/Poses/Poses";
import {MiaDirection} from "./Mia";

export interface MiaButtonProps {
    openMia: () => void;
    visible: boolean;
    miaSettings?: MiaSettings;
    direction?: MiaDirection;
    formatIdentifier?: string;
}

export const MiaButton: FunctionComponent<MiaButtonProps> = ({
                                                                 openMia,
                                                                 visible,
                                                                 miaSettings,
                                                                 direction = MiaDirection.RIGHT,
                                                             }) => {

    function click() {
      openMia();
    }

    if (!visible) {
        return <div/>
    }


    return (
        <div
            className={styles.miaButton}
            onClick={click}
        >
            <div className={direction===MiaDirection.LEFT?styles.miaLeft:styles.miaRight}>
                <MiaCustomization pose={MiaPose.DEFAULT}
                                  hairColor={miaSettings?.customHair}
                                  eyesColor={miaSettings?.customEyes}
                                  skinTone={miaSettings?.customSkin}
                                  jacketColor={miaSettings?.customJacket}
                                  pantsColor={miaSettings?.customPants}
                                  accessoriesColor={miaSettings?.customAccessories}/>
            </div>
        </div>
    );
};
