import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./Chat.module.scss";
import { Icon, IconType } from "@dis/ycg-components";
import { useIntl } from "react-intl";
import { MiaCustomization } from "../../MiaCustomization/MiaCustomization";
import { MiaSettings } from "../../../../api/userApi";
import { MiaPose } from "../../MiaCustomization/Poses/Poses";
import { MiaChatHistory, ChatMessage } from "../../../../redux/miaChatSlice";
import { ROUTES } from "../../../../utils/routes";
import { TypingIndicator } from "../../../TypingIndicator/TypingIndicator";

export interface ChatProps {
  onSend: (message: string) => void;
  chatHistory: MiaChatHistory;
  userAvatar: string | undefined;
  miaSettings?: MiaSettings;
  path: string;
  pending: boolean;
}

export enum Sender {
  Mia,
  User
}

export const Chat: FunctionComponent<ChatProps> = ({
                                                     onSend,
                                                     chatHistory,
                                                     userAvatar,
                                                     miaSettings,
                                                     path,
                                                     pending
                                                   }) => {

  const [input, setInput] = useState<string | undefined>("");
  const [miaChatHistory, setMiaChatHistory] = useState<ChatMessage[]>([]);

  const intl = useIntl();

  useEffect(() => {
    if (chatHistory !== undefined) {
      if (chatHistory?.miaDashboardChatHistory?.length > 0 && path === ROUTES.DASHBOARD.BASE) {
        setMiaChatHistory(chatHistory.miaDashboardChatHistory);
      } else if (chatHistory?.miaCourseChatHistory?.length > 0) {
        setMiaChatHistory(chatHistory.miaCourseChatHistory);
      }
    }
  }, [chatHistory, path]);

  function send() {
    if (input) {
      onSend(input);
      setInput("");
    }
    if (localStorage.getItem("dashboard_first_visit") === "true" && (path === ROUTES.DASHBOARD.BASE)) {
      localStorage.setItem("dashboard_first_visit", "false");
    } else if (localStorage.getItem("course_first_visit") === "true" && (path !== ROUTES.DASHBOARD.BASE)) {
      localStorage.setItem("course_first_visit", "false");
    }
  }

  function updateInput(e: React.ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value);
  }

  function handleButtonPress() {
    send();
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      send();
    }
  };

  return (
    <div className={styles.chat}>
      <div className={styles.scrollable}>
        <div className={styles.chatHistory}>
          {miaChatHistory && miaChatHistory?.length > 0 && miaChatHistory.map((msg) => {
            if (msg.sender === Sender.User) {
              return (
                <div className={styles.userMessage} key={msg.output}>
                  <div className={styles.textCell}>
                    <div className={styles.textContent}>
                      {msg.output}
                    </div>
                  </div>
                  <div className={styles.avatar}>
                    {!userAvatar &&
                      <Icon name={"account_circle"} size={"32px"} color={"#c8c896"} />
                    }
                    {userAvatar &&
                      <img src={userAvatar} alt={"user avatar"} />
                    }
                  </div>
                </div>
              );
            } else {
              return (
                <div className={styles.miaMessage} key={msg.output}>
                  <div className={styles.avatar}>
                    <div className={styles.svg}>
                      <MiaCustomization pose={MiaPose.DEFAULT}
                                        hairColor={miaSettings?.customHair}
                                        eyesColor={miaSettings?.customEyes}
                                        skinTone={miaSettings?.customSkin}
                                        jacketColor={miaSettings?.customJacket}
                                        pantsColor={miaSettings?.customPants}
                                        accessoriesColor={miaSettings?.customAccessories} />
                    </div>
                  </div>
                  <div className={styles.textCell}>
                    <div className={styles.textContent}>
                      {msg.output}
                    </div>
                  </div>
                </div>
              );
            }
          })
          }
          {pending &&
            <div className={styles.miaMessage}>
              <div className={styles.avatar}>
                <div className={styles.svg}>
                  <MiaCustomization pose={MiaPose.DEFAULT}
                                    hairColor={miaSettings?.customHair}
                                    eyesColor={miaSettings?.customEyes}
                                    skinTone={miaSettings?.customSkin}
                                    jacketColor={miaSettings?.customJacket}
                                    pantsColor={miaSettings?.customPants}
                                    accessoriesColor={miaSettings?.customAccessories} />
                </div>
              </div>
              <div className={styles.textCell} style={{flex: "initial"}}>
                <div className={styles.textContent}>
                  <TypingIndicator/>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className={styles.textBoxWrapper}>
        <div className={styles.textBox}>
          <input
            value={input}
            onChange={updateInput}
            placeholder={intl.formatMessage({ id: "Mia.Placeholder" })}
            className={styles.inputField}
            onKeyDown={handleKeyPress}
          />
          <button onClick={handleButtonPress} className={styles.tertiaryButton}>
            <div className={styles.icon}>
              <Icon name={"send"} type={IconType.PRIMARY} />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
