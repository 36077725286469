import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styles from './CodingChallenge.module.scss';
import { loadJsonFile, MEDIA_TABLET_WIDTH, MEDIA_MOBILE_WIDTH } from '../../utils/helper';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BackupIcon from '@mui/icons-material/Backup';
import { BreadcrumbGroup, BreadcrumbTabProps, Button, ButtonType, FileUploadButton, Carousel } from '@dis/colors-components';
import { useIntl } from 'react-intl';
import { SCOPE_CODING_CHALLENGE, userFilesApi } from '../../api/UserFilesApi';
import { useDispatch, useSelector } from 'react-redux';
import { codingChallengeImageSet, selectCodingChallengeImage } from '../../redux/codingImageSlice';
import { CourseIntro } from '../../building-blocks/CourseIntro/CourseIntro';
import { AgeGroups } from '../../components/AgeGroupDisplay/AgeGroupDisplay';
import { useKeycloak } from '@react-keycloak/web';
import { Competence } from '@dis/types';
import { selectLanguage } from '../../redux/languageSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { selectMiaSettings } from '../../redux/userSettingsSlice';
import { MiaButton } from '../../components/Mia/MiaButton';
import { MiaDirection } from '../../components/Mia/Mia';
import { MiaHandler } from '../../components/Mia/MiaHandler/MiaHandler';
import { selectMediaQueries } from "../../redux/mediaQueriesSlice";

interface ImageProps {
  id?: string;
  image: string;
  imageAlt: string;
}

interface CodingProps {
  title: string;
  description: string;
  guidelineTitle: string;
  guideLineImage: string;
  guideLineImageAlt: string;
}

interface CourseIntroProps {
  description: string;
  name: string;
  competencies: Array<Competence>;
  duration: number;
  author: string;
  image: string;
  previous_knowledge: number;
  ageGroups: AgeGroups;
}

interface CodingChallengeContentProps {
  courseIntro: CourseIntroProps;
  codingChallenge: CodingProps;
  uploadImage: Array<ImageProps>;
  favoriteCoding: Array<ImageProps>;
}

interface CodingChallengeProps {
  miaPosition?: boolean;
}

const IMAGE_OFFSET_WIDTH = 60;

const CodingChallenge: FunctionComponent<CodingChallengeProps> = ({miaPosition}) => {
  const [
    codingChallengeData,
    setCodingChallengeData
  ] = useState<CodingChallengeContentProps>();

  const uploadedImage = useSelector(selectCodingChallengeImage);

  const { keycloak, initialized } = useKeycloak();

  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(selectLanguage);
  const { mediaWidth } = useSelector(selectMediaQueries);

  /* newly added Mia handling */
  const [showMia, setShowMia] = useState<boolean>(false);
  const [scrollSpeed, setScrollSpeed] = useState<number>(0.75);
  const miaSettings = useSelector(selectMiaSettings);
  const closeMia = () => {
    setShowMia(false);
  };
  const openMia = useCallback(() => {
    setShowMia(true);
  }, []);

  useEffect(() => {
    //set scroll speed
    if(mediaWidth <= MEDIA_MOBILE_WIDTH) setScrollSpeed(1);
    else setScrollSpeed(0.75);
  },[scrollSpeed, mediaWidth])

  useEffect(() => {
    async function loadData() {
      let data;
      switch (selectedLanguage) {
        case 'DEU':
          data = await loadJsonFile(
            `${process.env.PUBLIC_URL}/content/mitmach-angebote/coding-challenge/page-content.json`
          );
          break;
        case 'ENG':
          data = await loadJsonFile(
            `${process.env.PUBLIC_URL}/content/mitmach-angebote/coding-challenge/page-content-eng.json`
          );
          break;
        default:
          data = await loadJsonFile(
            `${process.env.PUBLIC_URL}/content/mitmach-angebote/coding-challenge/page-content.json`
          );
          break;
      }
      setCodingChallengeData(await data.json());
    }

    loadData();

    async function loadFiles() {
      let userId = null;
      if (initialized && keycloak.authenticated) {
        await keycloak.loadUserInfo().then((profile: any) => {
          userId = profile.sub;
        });
      }

      if (userId) {
        const receivedImage = await userFilesApi.getImage(
          userId,
          SCOPE_CODING_CHALLENGE
        );
        if (receivedImage !== undefined) {
          dispatch(codingChallengeImageSet(receivedImage));
        }
      }
    }
    loadFiles();
  }, [dispatch, initialized, keycloak, selectedLanguage]);

  const handleOnClickOpenCodeEditor = (): void => {
    // setShowMia(true);
    window.open('https://jsfiddle.net/9pjqtvn3/', "_blank", "noreferrer, noopener");
  };

  const handleInputFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const contentType = e.target.files[0].type.toLowerCase();
      if (contentType.startsWith('image')) {
        const formData = new FormData();
        formData.append('file', e.target.files[0], SCOPE_CODING_CHALLENGE);

        const response = await userFilesApi.postImage(
          formData,
          SCOPE_CODING_CHALLENGE
        );

        if (response.ok) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            dispatch(codingChallengeImageSet(event.target.result));
          };
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    }
  };

  function fontSize () {
    if(mediaWidth <= MEDIA_MOBILE_WIDTH) {
      return "24px";
    } else if(mediaWidth > MEDIA_MOBILE_WIDTH && mediaWidth <= MEDIA_TABLET_WIDTH) {
      return "28px";
    } else {
      return ""
    }
  }

  const headerStyle = {
    fontSize: fontSize()
  }

  function miaStyle() {
    if(mediaWidth <= MEDIA_TABLET_WIDTH && showMia) {
      return {
        zIndex: 12
      }
    }
    return {
      bottom: miaPosition && mediaWidth <= MEDIA_TABLET_WIDTH ? "110px" : ""
    }
  }

  const style = {
    width: mediaWidth <= MEDIA_MOBILE_WIDTH ? `${mediaWidth - IMAGE_OFFSET_WIDTH}px` : ''
  }

  const breadcrumbTabs: Array<BreadcrumbTabProps> = [
    {
      label: intl.formatMessage({ id: 'Dashboard.name' }),
      onClick: () => navigate(ROUTES.DASHBOARD.BASE)
    },
    { label: intl.formatMessage({ id: 'CodingChallenge.breadcrumbLabel' }) }
  ];
  return (
    <div className={styles.CodingChallenge}>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs} />
      </div>
      {codingChallengeData && (
        <div>
          <CourseIntro
            name={codingChallengeData?.courseIntro.name}
            description={codingChallengeData.courseIntro.description}
            duration={codingChallengeData.courseIntro.duration}
            ageGroups={codingChallengeData.courseIntro.ageGroups}
            difficulty={codingChallengeData.courseIntro.previous_knowledge}
            author={codingChallengeData.courseIntro.author}
            image={codingChallengeData.courseIntro.image}
            competences={codingChallengeData.courseIntro.competencies}
          />
        </div>
      )}
      {codingChallengeData && (
        <div className={styles.openEditor}>
          <div className={styles.codingChallengeContent}>
            <div className={styles.title}>{codingChallengeData?.codingChallenge.title}</div>
            <div className={styles.description}>{codingChallengeData?.codingChallenge.description}</div>
            <div className={styles.buttons}>
              <Button label={intl.formatMessage({ id: 'CodingChallenge.CodeEditorOpen' })}
                      onClick={handleOnClickOpenCodeEditor}
                      type={ButtonType.PRIMARY}
                      icon={<OpenInNewIcon />}
                      width={'360px'}
                      height={'50px'}
              />
              <div className={styles.fileUpload}>
                <FileUploadButton
                  label={intl.formatMessage({ id: 'CodingChallenge.FileUploadButtonLabel' })}
                  icon={<BackupIcon />}
                  onChange={e => handleInputFile(e)}
                />
              </div>
            </div>
            <div className={styles.guidelineWrapper}>
              <div className={styles.guidelineTitle}>
                {codingChallengeData.codingChallenge.guidelineTitle}
              </div>
              <img src={codingChallengeData?.codingChallenge.guideLineImage}
                   alt={codingChallengeData.codingChallenge.guideLineImageAlt} />
            </div>
          </div>
        </div>
      )}
      {uploadedImage && (
        <div className={styles.uploadImageWrapper}>
          <h2 className={styles.uploadImageTitle}>
            {intl.formatMessage({ id: 'CodingChallenge.yourUpload' })}
          </h2>
          {uploadedImage && <img src={uploadedImage} alt={'uploaded file'} />
          }
        </div>
      )}

      <div className={styles.codingImagesWrapper}>
        {codingChallengeData && codingChallengeData.favoriteCoding && codingChallengeData.favoriteCoding.length > 0 &&
          <Carousel scrollSpeed={scrollSpeed} headerStyle={headerStyle} header={intl.formatMessage({ id: 'CodingChallenge.inspiration' })}>
            <div className={styles.images}>
              {codingChallengeData?.favoriteCoding.map((item) => <img style={style} src={item.image} alt={item.imageAlt} key={item.id}/>
              )}
            </div>
          </Carousel>
        }
      </div>
      <div className={styles.miaFixed} style={miaStyle()}>
        <MiaButton
          openMia={openMia}
          visible={!showMia}
          miaSettings={miaSettings}
          direction={MiaDirection.LEFT}
        />
        <div className={styles.miaHandler}>
          <MiaHandler
            onClose={closeMia}
            open={showMia}
            onOpen={openMia}
            miaDirection={MiaDirection.LEFT}
            miaSettings={miaSettings}
            courseName={codingChallengeData?.courseIntro.name}
          />
        </div>
      </div>
    </div>
  );
};
export default CodingChallenge;
