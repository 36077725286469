import React, {FunctionComponent, useEffect, useState} from 'react';
import {To, useNavigate} from "react-router-dom";
import {Button, ButtonType} from "@dis/colors-components";
import {JobmatchStepProps} from "./JobmatchPage";
import {useDispatch, useSelector} from 'react-redux';
import {
  selectAllCompetencies,
  selectFilteredJobs,
  setCurrentStep,
  setWeightedResultingJobs
} from "../../redux/jobmatchSlice";
import styles from './JobmatchPage.module.scss';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';

export const rateValues = {
  // all addends 1 higher as planned to prevent negative result
  superlike: 3,
  like: 2,
  dislike: 0,
  bonusMult: 2
}
const JobmatchStep2: FunctionComponent<JobmatchStepProps> = ({step, prev, next}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allCompetencies = useSelector(selectAllCompetencies);
  const filteredJobs = useSelector(selectFilteredJobs);

  const extractedCompetenceIdsFromFilteredJobs = new Set(filteredJobs.flatMap(job => job.competencies));
  const competenciesUsedByFilteredJobs = allCompetencies.filter(competence =>
    extractedCompetenceIdsFromFilteredJobs.has(competence.id)
  );

  useEffect(() => {
    dispatch(setCurrentStep(step))
  }, [step, dispatch]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [ratings, setRatings] = useState<Map<number, number>>(new Map());
  const [buttonDisabled, setButtonDisabled] = useState(true);
  let currentQuestion = competenciesUsedByFilteredJobs[currentIndex];
  const [showCompetencies, setShowCompetencies] = useState(true);

  const initialClicked = {
    starClicked: false,
    likeClicked: false,
    dislikeClicked: false
  };
  const [clicked, setClicked] = useState(initialClicked);

  const handleRatingChange = (rating: number) => {
    // Save the rating for the current question
    setRatings(prevRatings =>
      new Map(prevRatings.set(competenciesUsedByFilteredJobs[currentIndex].id, rating)));
    if (currentIndex < competenciesUsedByFilteredJobs.length - 1) {
      setClicked(initialClicked);
      setCurrentIndex(currentIndex + 1);
    } else {
      setShowCompetencies(false);
      setButtonDisabled(false);
    }
  };

  const handleMouseDown = (key: keyof typeof clicked) => {
    setClicked((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const onNextClick = (next: To) => {
    calculateRatings();
    navigate(next);
  };

  const calculateRatings = ()=> {
    let weightedFilteredJobs = filteredJobs.map(job => {
      const totalWeight = job.competencies.reduce((sum, competence, index) => {
        const rating =  ratings.get(competence) || 0;
        const isPrioCompetence = index === 0;
        const weightedRating = isPrioCompetence ? rating * rateValues.bonusMult : rating;
        return sum + weightedRating;
      }, 0);
      return {...job, totalWeight};
    });

    weightedFilteredJobs.sort((a,b) => b.totalWeight - a.totalWeight);
    dispatch(setWeightedResultingJobs(weightedFilteredJobs));
  }

  return (
    <>
      <h4>Entscheide, welchen Aussagen du wie zustimmst.<br/>Wähle bei jeder Aussage „Ich stimme voll zu“ (⭐), „Ich
        stimme zu“ (👍) oder „Ich stimme nicht zu“ (👎).</h4>
      <div className={styles.stepContent}>
        {currentQuestion && showCompetencies &&
          <div key={currentQuestion.id} className={styles.competenceLine}>
            {currentQuestion.text}
            <StarRateRoundedIcon className={`${styles.star} ${clicked.starClicked ? styles.starMarked : ""}`}
                                 onClick={() => handleRatingChange(rateValues.superlike)}
                                 onMouseDown={() => handleMouseDown("starClicked")}
            />
            <ThumbUpRoundedIcon className={`${styles.thumbsUp} ${clicked.likeClicked ? styles.thumbsUpMarked : ""}`}
                                onClick={() => handleRatingChange(rateValues.like)}
                                onMouseDown={() => handleMouseDown("likeClicked")}
            />
            <ThumbDownRoundedIcon className={`${styles.thumbsDown} ${clicked.dislikeClicked ? styles.thumbsDownMarked : ""}`}
                                  onClick={() => handleRatingChange(rateValues.dislike)}
                                  onMouseDown={() => handleMouseDown("dislikeClicked")}
            />
          </div>
        }
      </div>
      <div className={styles.buttons}>
        {prev && <Button label={"Zurück zur deinen Interessen"}
                         onClick={() => navigate(prev)}
                         type={ButtonType.SECONDARY}/>}
        {next && <Button label={"Weiter zur Berufe-Auswertung"}
                         onClick={() => onNextClick(next)}
                         disabled={buttonDisabled}/>}
      </div>
    </>
  )
}

export default JobmatchStep2;
