import React, {FunctionComponent} from 'react';
import styles from './Mia.module.scss';
import {Icon, IconType} from '@dis/ycg-components'
import {MiaCustomization} from './MiaCustomization/MiaCustomization';
import {ROUTES} from '../../utils/routes';
import {NavigateFunction} from 'react-router-dom';
import {MiaSettings} from '../../api/userApi';
import {MiaPose} from './MiaCustomization/Poses/Poses';
import {useDispatch} from 'react-redux';
import {miaCustomizationUrlSet} from '../../redux/miaCustomizationUrlSlice';
import classNames from "classnames";
import logo from "../../assets/images/logo.png";
import {useIntl} from "react-intl";

export enum MiaDirection {
    LEFT,
    RIGHT,
}

export interface MiaProps {
    type: MiaPose;
    direction: MiaDirection;
    onClose: () => void;
    open: boolean;
    miaSettings?: MiaSettings;
    loggedIn?: boolean;
    navigate?: NavigateFunction;
    path?: string;
}


export const Mia: FunctionComponent<MiaProps> = ({
                                                     type,
                                                     direction,
                                                     children,
                                                     onClose,
                                                     open,
                                                     miaSettings,
                                                     loggedIn,
                                                     navigate,
                                                     path,
                                                 }) => {

    const dispatch = useDispatch();
    const intl = useIntl();

    const pointerStyle = () => {
        switch (direction) {
            case MiaDirection.LEFT:
                return styles.pointer_0;
            case MiaDirection.RIGHT:
                return styles.pointer_1;
            default:
                return styles.pointer_0;
        }
    }

    function closeMia() {
      onClose();
    }

    if (!open) {
        return <div/>
    }

    function settingsIcon() {
        if (navigate) {
            navigate(ROUTES.MIA_CUSTOMIZATION);
            dispatch(miaCustomizationUrlSet(path));
        }
    }

    return (
        <div className={styles.mia}>
            <div className={styles.chatBubble}>
                <div className={styles.icons}>
                    <div className={styles.logo}>
                        <img src={logo} alt={intl.formatMessage({id: "Header.logoAlt"})}/>
                    </div>
                    <div className={styles.iconsWrapper}>
                        {loggedIn &&
                        <Icon name={'settings'} onClick={settingsIcon}
                            type={IconType.PRIMARY}
                            size={'18px'}/>
                        }
                        <div className={styles.close}>
                            <Icon name={'keyboard_arrow_down'} type={IconType.PRIMARY} onClick={closeMia} size={'35px'}/>
                        </div>
                    </div>
                </div>
                {children}
                <div className={classNames(
                    styles.pointer,
                    pointerStyle())
                }>
                    <div className={styles.border}/>
                    <div className={styles.fill}/>
                </div>
            </div>
            <div className={classNames(
                styles.miaCustomization,
                direction === MiaDirection.LEFT ? styles.miaLeft : styles.miaRight)
            }>
                <MiaCustomization
                    pose={type}
                    eyesColor={miaSettings?.customEyes}
                    hairColor={miaSettings?.customHair}
                    skinTone={miaSettings?.customSkin}
                    jacketColor={miaSettings?.customJacket}
                    pantsColor={miaSettings?.customPants}
                    accessoriesColor={miaSettings?.customAccessories}
                />
            </div>
        </div>
    );
};
