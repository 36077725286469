import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./SelectableTilesCategory.module.scss";
import { SelectableTile, ToggleButtons, TileContentsProps } from "@dis/colors-components";
import { useIntl } from "react-intl";
import { loadJsonFile, MEDIA_MOBILE_WIDTH } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../../redux/languageSlice";
import { selectMediaQueries } from "../../redux/mediaQueriesSlice";
import jsonpatch from "fast-json-patch";
import { userApi, UserSettings } from "../../api/userApi";
import {
  ageSet,
  areaOfInterestsSet,
  codingLevelSet,
  selectUserInterests,
  topicsSet,
  UserInterestsState
} from "../../redux/userInterestsSlice";

export interface PageData {
  areaOfInterests: Array<TileContentsProps>;
  ages: Array<string>;
  codingLevels: Array<string>;
  topics: Array<TileContentsProps>;
}

export interface SelectableTilesCategoryProps {
  profilePage?: boolean;
}

export const updatingUserSettings = (userSettings: UserSettings, userInterests: UserInterestsState, nickname?: string) => {

  const { areaOfInterests, topics, age, codingLevel } = userInterests;

  const updatedUserSettings = jsonpatch.deepClone(userSettings);

  if (!updatedUserSettings.personalizationPageSettings) {
    updatedUserSettings.personalizationPageSettings = {};
  }

  if(updatedUserSettings.personalizationPageSettings) {
    updatedUserSettings.personalizationPageSettings.areaOfInterests = areaOfInterests;
    updatedUserSettings.personalizationPageSettings.topics = topics;
    updatedUserSettings.personalizationPageSettings.age = age;
    updatedUserSettings.personalizationPageSettings.codingLevel = codingLevel;
  }
   if(nickname) {
     updatedUserSettings.userName = nickname;
   }

  return userApi.patchUserSettings(userSettings, updatedUserSettings);
}

export const SelectableTilesCategory: FunctionComponent<SelectableTilesCategoryProps> = ({
                                                                                           profilePage
                                                                                        }) => {

  const intl = useIntl();
  const dispatch = useDispatch();

  const selectedLanguage = useSelector(selectLanguage);
  const [pageContent, setPageContent] = useState<PageData>();
  const {mediaWidth} = useSelector(selectMediaQueries);
  const { areaOfInterests, topics, age, codingLevel } = useSelector(selectUserInterests);

  const areaOfInterestsOnChange = (event: React.MouseEvent<HTMLElement>, newAreaOfInterests: string[]) => dispatch(areaOfInterestsSet(newAreaOfInterests));
  const topicsOnChange = (event: React.MouseEvent<HTMLElement>, newTopics: string[]) => dispatch(topicsSet(newTopics));
  const ageOnChange = (event: React.MouseEvent<HTMLElement>, newAge: string) => dispatch(ageSet(newAge));
  const codingLevelOnChange = (event: React.MouseEvent<HTMLElement>, newCodingLevel: string) => dispatch(codingLevelSet(newCodingLevel));

  useEffect(() => {

    async function loadData() {
      let data;
      switch (selectedLanguage) {
        case "DEU":
          data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/registration-personalization/page-content.json`);
          break;
        case "ENG":
          data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/registration-personalization/page-content-eng.json`);
          break;
        default:
          data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/registration-personalization/page-content.json`);
          break;
      }
      setPageContent(await data.json());
    }

    loadData().catch(console.error);
  }, [selectedLanguage]);

  const ageWidth = mediaWidth <= MEDIA_MOBILE_WIDTH ? '190px' : '250px';
  const codingLevelWidth = mediaWidth <= MEDIA_MOBILE_WIDTH ? '260px' : '350px';

  return (
    <div className={styles.selectableTilesCategory}>
      {pageContent &&
        <>
        <div className={styles.selectableTile}>
          <h3>{intl.formatMessage({ id: "RegistrationPersonalizationPage.areaOfInterestsTitle" })} {!profilePage && intl.formatMessage({ id: "Profile.selectThreeInterests" })}</h3>
          <SelectableTile tileContents={pageContent.areaOfInterests} onChange={areaOfInterestsOnChange}
                          value={areaOfInterests} />
        </div>
        <div className={styles.toggleButton}>
        <h3>{intl.formatMessage({id: "RegistrationPersonalizationPage.ageTitle"})}</h3>
        <ToggleButtons labels={pageContent.ages} width={ageWidth} onChange={ageOnChange} value={age}/>
        </div>
        <div className={styles.toggleButton}>
        <h3>{intl.formatMessage({id: "RegistrationPersonalizationPage.codingLevelTitle"})}</h3>
        <ToggleButtons labels={pageContent.codingLevels} width={codingLevelWidth} onChange={codingLevelOnChange} value={codingLevel}/>
        </div>
        <div className={styles.selectableTile}>
        <h3>{intl.formatMessage({id: "RegistrationPersonalizationPage.topicsTitle"})} {!profilePage && intl.formatMessage({id: "Profile.selectThreeTopics"})}</h3>
        <SelectableTile tileContents={pageContent.topics} onChange={topicsOnChange} value={topics}/>
        </div>
        </>
      }
    </div>
  );
};
