import React, {FunctionComponent, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, ButtonType} from "@dis/colors-components";
import {JobmatchStepProps} from "./JobmatchPage";
import {useDispatch, useSelector} from 'react-redux';
import {
  selectChosenJobId,
  selectWeightedResultingJobs,
  setChosenJob,
  setCurrentStep
} from "../../redux/jobmatchSlice";
import styles from './JobmatchPage.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const JobmatchStep4: FunctionComponent<JobmatchStepProps> = ({step, prev, next}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentStep(step))
  }, [step, dispatch]);

  const chosenJobId = useSelector(selectChosenJobId);
  const matchedJobs = useSelector(selectWeightedResultingJobs);
  const [showText, setShowText] = useState(false);

  const job = matchedJobs.find(job => job.id === chosenJobId);
  dispatch(setChosenJob(job));


  const handleFold = () => {
    setShowText(true);
  }

  return (
    <>
      <h4>Das YouCodeGirls Berufe-Match hat ergeben, dass du dir den Beruf der <strong>{job && job.title}</strong> genauer anschauen solltest. Starte das Video, um mehr zu erfahren.</h4>
      <div className={styles.stepContent}>
        <div className={styles.videoWithText}>
          <video width="100%" controls={true}
                 src={`${process.env.PUBLIC_URL}/../content/jobmatch/videos/${job && job.id}.mp4`}/>
          <div className={styles.foldText} onClick={handleFold}>
            {job && showText ? job.videoDescription : "Text des Videos zum Nachlesen "}{!showText &&
            <ExpandMoreIcon className={styles.expandIcon}/>}
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        {prev && <Button label={"Zurück zur Berufe-Auswertung"}
                         onClick={() => navigate(prev)}
                         type={ButtonType.SECONDARY}/>}
        {next && <Button label={"Weiter"}
                         onClick={() => navigate(next)}/>}
      </div>
    </>
  )
}

export default JobmatchStep4;
