import React, {FunctionComponent, useEffect, useRef} from "react";
import lottie from "lottie-web";
import typingAnimation from "../../lotties/TypingAnimation.json";

export const TypingIndicator: FunctionComponent = () => {

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(ref.current) {
      lottie.loadAnimation({
        animationData: typingAnimation,
        container: ref.current,
        loop: true,
      });
    }
  },[]);

  return <div ref={ref} style={{height: "20px"}}>{}</div>
};