import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {
  BreadcrumbGroup,
  BreadcrumbTabProps,
} from "@dis/colors-components";
import styles from "./ContactPage.module.scss";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/routes";
import {ContactForm} from "../../components/ContactForm/ContactForm";

const ContactPage: FunctionComponent = () => {

  const intl = useIntl();
  const navigate = useNavigate();

  const breadcrumbTabs: Array<BreadcrumbTabProps> = [
    {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
    {label: intl.formatMessage({id: 'Header.contact'})}
  ];

  return (
    <>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
      </div>
      <ContactForm navigateAfterResponseOk/>
    </>
  );
};

export default ContactPage;
