import React, {FunctionComponent, useEffect, useState} from "react";
import styles from "./StartpageBannerCarousel.module.scss";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/routes";
import {MilestoneBanner} from "../MilestoneBanner/MilestoneBanner";
import {selectLanguage} from "../../redux/languageSlice";
import {loadJsonFile} from "../../utils/helper";
import {useKeycloak} from "@react-keycloak/web";

/* maps milestones into a carousel. Uses MilestoneBanner for presentation */
export const StartpageBannerCarousel: FunctionComponent = () => {

    const navigate = useNavigate();
    const selectedLanguage = useSelector(selectLanguage);

    const { keycloak } = useKeycloak();

    interface MilestoneProps {
        title: string,
        image: string,
        description: string,
        buttonLabel: string,
        buttonDestination: string
    }

    interface MilestoneData {
        milestones: MilestoneProps[];
    }

    const [milestonesContent, setMilestonesContent] = useState<MilestoneData>();

    const [lastIndex, setLastIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        async function loadData() {
            let data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/milestones/milestones-deu.json`);
            setMilestonesContent(await data.json());
        }

        loadData().catch(console.error);
    }, [selectedLanguage]);

    useEffect(() => {
        if (milestonesContent && milestonesContent.milestones) {
            setLastIndex(milestonesContent.milestones.length - 1);
        }
    }, [milestonesContent]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(currentIndex === lastIndex ? 0 : currentIndex + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, [currentIndex, lastIndex]);

    const onMilestoneButtonClick = (route: string) => {
        switch (route) {
            case "register": if (keycloak) {navigate(ROUTES.REGISTER.BASE)}
            break;
            case "jobmatch": navigate(ROUTES.DASHBOARD.JOBMATCH.INDEX);
            break;
            case "handout": navigate(ROUTES.DASHBOARD.HANDOUT);
            break;
            default: break;
        }
    };

    return (
        <div className={styles.startpageBannerCarousel}>
            {milestonesContent && milestonesContent.milestones &&
              <div className={styles.carouselContent}>
                  {milestonesContent.milestones.map((milestoneContent, index) =>
                      <div key={milestoneContent.title}
                           className={index === currentIndex ? styles.activeSlide : styles.inactiveSlide}
                      >
                          {index === currentIndex &&
                            <MilestoneBanner
                              buttonLabel={milestoneContent.buttonLabel}
                              onButtonClick={() => onMilestoneButtonClick(milestoneContent.buttonDestination)}
                              title={milestoneContent.title}
                              description={milestoneContent.description}
                              image={milestoneContent.image}
                            />
                          }
                      </div>
                  )}
              </div>
            }
            <div className={styles.indicators}>
                {milestonesContent && milestonesContent.milestones &&
                    milestonesContent.milestones.map((milestoneContent, index) =>
                        <span
                            key={milestoneContent.title}
                            className={classNames(styles.indicator, currentIndex === index ? styles.activeIndicator : styles.inactiveIndicator)}
                            onClick={() => setCurrentIndex(index)}
                        />
                )}
            </div>
        </div>
    );
};
