import React, {FunctionComponent} from 'react';
import styles from './CustomizationOption.module.scss';


export interface MiaCustomizationOption {
    id: string,
    previewColor1: string,
    previewColor2: string,
}

export interface CustomizationOptionProps {
    selected: boolean;
    onClick: () => void;
    color1: string;
    color2?: string;
}


export const CustomizationOption: FunctionComponent<CustomizationOptionProps> = ({
                                                                                selected,
                                                                                color1,
                                                                                color2,
                                                                                onClick
                                                                            }) => {

    if(!color2) {
        color2 = color1;
    }

    return (
        <div onClick={onClick} className={selected?styles.selected:styles.CustomizationOption}>
            <div
                style={{background: `linear-gradient(to bottom right, ${color1}, ${color2})`, "flex": "1" }}
            />
        </div>
    );
};
