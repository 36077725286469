import styles from './EyesColorCustomizations.module.scss';
import {CustomizationOptionPreview, Customizations, CustomizationsStyles} from "./Customizations";


export enum EyesColorCustomizationsIds {
    DEFAULT = "eyes_0",
    TYPE_1 = "eyes_1",
    TYPE_2 = "eyes_2",
}

const EyesColorCustomizationPreviews: CustomizationOptionPreview<EyesColorCustomizationsIds>[] = [
    {id: EyesColorCustomizationsIds.DEFAULT, previewColor1: "#184e43", previewColor2: "#789475"},
    {id: EyesColorCustomizationsIds.TYPE_1, previewColor1: "#112438", previewColor2: "#547089"},
    {id: EyesColorCustomizationsIds.TYPE_2, previewColor1: "#20130B", previewColor2: "#3D2818"}
]

const EyesColorStyles: CustomizationsStyles<EyesColorCustomizationsIds> = {
    [EyesColorCustomizationsIds.DEFAULT]: styles.eyes_0,
    [EyesColorCustomizationsIds.TYPE_1]: styles.eyes_1,
    [EyesColorCustomizationsIds.TYPE_2]: styles.eyes_2,
}

const EyesColorCustomizations: Customizations<EyesColorCustomizationsIds> = {
    previews: EyesColorCustomizationPreviews,
    styles: EyesColorStyles,
}

export default EyesColorCustomizations;
