import {miaServiceUrl} from "./util";
import {MiaResponse} from "../utils/types/api";
import {MiaPose} from "../components/Mia/MiaCustomization/Poses/Poses";

export enum Status {
  loggedIn = "logged_in",
  notLoggedIn = "not_logged_in",
  newlyRegistered = "registered",
}

const conversationEndpoint = "webhooks"
const closeEndpoint = "close";
const miaErrorMessage = "Leider verstehe ich nicht ganz, was du meinst, lerne aber mit jeder deiner Fragen dazu. Kannst du deine Frage vielleicht anders formulieren?"

// ask a question in Chatbot mode, and return an answer
const getMiaResponse = async (question: string, location: string, device: string, myuuid: string | null, loggedin?: boolean): Promise<MiaResponse> => {

  const jsonData = {
    "sender": myuuid,
    "message": question,
    "metadata": {
      "Kurs": location,
      "sender": myuuid,
      "loggedin": loggedin,
      "device": device
    }
  };
  const conversationResponse = await fetch(`${miaServiceUrl}/${conversationEndpoint}`, {
    method: "POST",
    mode: "cors",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(jsonData)
  });

  let miaResponse = undefined;
  if (conversationResponse.ok) {
    miaResponse = await conversationResponse.json() as MiaResponse;
  } else {
    console.warn("Invalid response from Mia Backend");
  }

  if (miaResponse) {
    return miaResponse;
  } else {
    return {
      output: miaErrorMessage,
      miaType: MiaPose.DEFAULT,
      clearOnHide: false
    };
  }
};

const closeMiaApi = async (myuuid: string | null) => {

  const jsonData = {
    "sender": myuuid
  };

  const closeResponse = await fetch(`${miaServiceUrl}/${closeEndpoint}`, {
    method: "POST",
    mode: "cors",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(jsonData)
  })

  let miaResponse = undefined;
  if (closeResponse.ok) {
    miaResponse = await closeResponse.json();
  } else {
    console.warn("Invalid response from Mia Backend");
  }

  if (miaResponse) {
    console.log(miaResponse.message);
  }
}

export const miaApi = {
  getMiaResponse,
  closeMiaApi
};
