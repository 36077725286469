import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./CoursePage.module.scss";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectFormatConfigurationFromLesson, selectLesson } from "../../redux/lessonsSlice";
import { CourseIntroWrapper } from "../../building-blocks/CourseIntro/CourseIntroWrapper";
import { getFormatBaseUrl, getFormatPreviewSource } from "../../utils/formatHelper";
import { fetchLessons } from "../../redux/fetchActions";
import { ROUTES } from "../../utils/routes";
import { MiaButton } from "../../components/Mia/MiaButton";
import { MiaHandler } from "../../components/Mia/MiaHandler/MiaHandler";
import { MiaDirection } from "../../components/Mia/Mia";
import { FormatConfigurationWithMetaData, ShallowEntity } from "../../utils/types/api";
import { useIntl } from "react-intl";
import { saveUserSettings, selectMiaSettings, selectUserSettings } from "../../redux/userSettingsSlice";
import { useKeycloak } from "@react-keycloak/web";
import { RootState } from "../../redux/store";
import { CourseModalDisplay } from "../../building-blocks/CourseModalDisplay/CourseModalDisplay";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  BreadcrumbGroup,
  BreadcrumbTabProps,
  Button,
  ButtonFlexibility,
  ButtonType,
  Modal,
  NotificationBanner,
  NotificationBannerType
} from "@dis/colors-components";
import jsonpatch from "fast-json-patch";
import { userApi } from "../../api/userApi";
import { selectMediaQueries } from "../../redux/mediaQueriesSlice";
import {MEDIA_TABLET_WIDTH, MEDIA_MOBILE_WIDTH} from "../../utils/helper";
import classNames from "classnames";
import { selectUserLoggedIn, selectUserProfile } from "../../redux/userLoggedInSlice";
import { fetchEndOfCourseRecommendations } from "../../redux/endOfCourseRecommendationsSlice";

const CONTENT_ID_QUERY = "content-id";
const FORMAT_IDENTIFIER_EXTERNAL_LESSON = "externalLesson";
const FORMAT_IDENTIFIER_EXTERNAL_WORKSHOP  = "externalWorkshop";
const END_OF_COURSE_RECOMMENDATIONS_AMOUNT = 3;

/** format types **/
const FORMAT_TYPE_APP = "app";
const FORMAT_TYPE_CODING_CAMP = "coding camp";
const FORMAT_TYPE_HACKATHON = "hackathon";
const FORMAT_TYPE_INFORMATION_MATERIAL = "informationsmaterial";
const FORMAT_TYPE_LAB = "lab";
const FORMAT_TYPE_EDUCATIONAL_GAME = "lernspiel";
const FORMAT_TYPE_LEARNING_TOOL = "lerntool";
const FORMAT_TYPE_OFFLINE_ACTIVITIES = "offline-aktivitäten";
const FORMAT_TYPE_ONLINE_COURSE = "online-kurs";
const FORMAT_TYPE_ONLINE_WORKSHOP = "online-workshop";
const FORMAT_TYPE_PDF = "pdf";
const FORMAT_TYPE_IN_PERSON_WORKSHOP = "präsenzworkshop";
const FORMAT_TYPE_SELF_TEST = "selbsttest";
const FORMAT_TYPE_EDUCATIONAL_MATERIAL = "unterrichtsmaterial";
const FORMAT_TYPE_VIDEO = "video";
const FORMAT_TYPE_MITMACH_ANGEBOT = "mitmach-angebot";

interface CoursePageProps {
  storybook?: boolean;
  miaPosition?: boolean;
}

const MODAL_BACKGROUND_WINDOW_COLOR = "#f1f8f9";
const CLOSE_ICON_COLOR = "#CD4C54";

//time in milliseconds, six minutes
const REGISTER_MODAL_POPUP_TIME = 360000;

const CoursePage: FunctionComponent<CoursePageProps> = ({ storybook, miaPosition }) => {
  const search = new URLSearchParams(useLocation().search);
  const contentId = search.get(CONTENT_ID_QUERY);
  let id = contentId ? contentId : "";

  const intl = useIntl();
  const { initialized } = useKeycloak();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLessons());
  }, [dispatch]);

  const formatConfiguration = useSelector(
    selectFormatConfigurationFromLesson(id)
  );

  const loggedIn = useSelector(selectUserLoggedIn);
  const miaSettings = useSelector(selectMiaSettings);
  const userSettings = useSelector(selectUserSettings);
  const startedCourses: Array<string> | undefined = userSettings.courseSettings?.startedCourses;
  const finishedCourses: Array<string> | undefined = userSettings.courseSettings?.finishedCourses;
  const lesson = useSelector((state: RootState) => selectLesson(state, id));
  const { mediaWidth, device } = useSelector(selectMediaQueries);
  const userProfile = useSelector(selectUserProfile);

  const [showMia, setShowMia] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [courseStarted, setCourseStarted] = useState<boolean>(false);
  const [courseFinished, setCourseFinished] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalClose, setModalClose] = useState<boolean>(false);

  const openInNewTabFunc = (formatConfig?: ShallowEntity<FormatConfigurationWithMetaData>) => {
    const formatType = formatConfig?.configuration.format_type.toLowerCase();
    const formatIdentifier = formatConfig?.formatIdentifier?.toLowerCase();

    if((formatType === FORMAT_TYPE_ONLINE_COURSE || formatType === FORMAT_TYPE_MITMACH_ANGEBOT ) && formatIdentifier !== FORMAT_IDENTIFIER_EXTERNAL_LESSON) {
      setShowMia(true);
    }

    if(!courseFinished) setShowButton(true);

    if(formatConfig) {
      window.open(getFormatPreviewSource(formatConfig), "_blank", "noreferrer, noopener");
    } else {
      window.open(formatConfiguration?.configuration.link, "_blank", "noreferrer, noopener");
    }
  }

  const addMilliseconds = (time: number | null) => {
    if(time) {
      return (time + REGISTER_MODAL_POPUP_TIME);
    } else {
     return new Date().getTime();
    }
  }

  const registerModalShow = () => {
    let newDate = new Date().getTime();
    const time = Number(localStorage.getItem('time'));
    if(!time || (addMilliseconds(time) <= newDate)) {
      localStorage.setItem('time', `${newDate}`);
      setIsOpen(true);
    } else {
      onModalClose();
    }
  }

  const openInNewTab = (formatConfig?: ShallowEntity<FormatConfigurationWithMetaData>): void => {
    if(!loggedIn) {
      registerModalShow();
    } else {
      !courseStarted && !showButton && addStartedCourse();
      !courseFinished && setShowButton(true);
      setCourseStarted(true);
      openInNewTabFunc(formatConfig);
    }
  };

  useEffect(() => {
    async function loadEndOfCourseRecommendations() {
      let userId = localStorage.getItem("uuid");

      if(loggedIn) {
        userId = userProfile.sub;
      }

      if(userId !== null && lesson !== undefined) {
        dispatch(fetchEndOfCourseRecommendations({courseId: lesson.name, userId, device, amount: END_OF_COURSE_RECOMMENDATIONS_AMOUNT }));
      }
    }

    loadEndOfCourseRecommendations();
  }, [dispatch, loggedIn, userProfile, lesson, device]);

  useEffect(() => {
    if(lesson) {
      if(startedCourses){
        if(startedCourses.includes(lesson.name)) {
          setCourseStarted(true);
          setShowButton(true);
        }
      }
      if(finishedCourses){
        if(finishedCourses.includes(lesson.name)) {
          setCourseFinished(true);
          setShowButton(false);
        }
      }
    }
  }, [lesson, startedCourses, finishedCourses]);

  const finishCourse = (): void => {
    setModal(true);
    setShowButton(false);
    setCourseFinished(true);
    if(loggedIn) addFinishedCourse();
  };

  const addFinishedCourse = async () => {
    await removeStartedCourse();
    const updatedSettings = jsonpatch.deepClone(userSettings);
    if (!updatedSettings.courseSettings) {
      updatedSettings.courseSettings = {};
    }
    if(lesson) {
      if(!updatedSettings.courseSettings.finishedCourses) updatedSettings.courseSettings.finishedCourses = [];
      updatedSettings.courseSettings.finishedCourses.push(lesson.name);
    }
    const updates = await userApi.patchUserSettings(userSettings, updatedSettings);
    if (updates) {
      dispatch(saveUserSettings(updates));
    }
  }

  const addStartedCourse = async () => {
    setCourseStarted(true);
    const updatedSettings = jsonpatch.deepClone(userSettings);
    if(!updatedSettings.courseSettings) {
      updatedSettings.courseSettings = {};
    }
    if(lesson) {
      if(!courseFinished) {
        if(!updatedSettings.courseSettings.startedCourses) updatedSettings.courseSettings.startedCourses = [];
        updatedSettings.courseSettings.startedCourses.push(lesson.name);
      }
    }
    const updates = await userApi.patchUserSettings(userSettings, updatedSettings);
    if(updates) {
      dispatch(saveUserSettings(updates));
    }
  }

  const removeStartedCourse = async () => {
    const updatedSettings = jsonpatch.deepClone(userSettings);

    if(!updatedSettings.courseSettings) {
      updatedSettings.courseSettings = {};
    }
    if(lesson) {
      let updatedStartedCourses = updatedSettings.courseSettings.startedCourses ?? [];
      updatedStartedCourses = updatedStartedCourses.filter((item: string) => item !== lesson.name);
      updatedSettings.courseSettings.startedCourses = updatedStartedCourses;
    }
    const updates = await userApi.patchUserSettings(userSettings, updatedSettings);
    if(updates) {
      dispatch(saveUserSettings(updates));
    }
  }

  useEffect(() => {
    if (
      (formatConfiguration?.formatIdentifier?.toLowerCase() === FORMAT_IDENTIFIER_EXTERNAL_LESSON
        || formatConfiguration?.formatIdentifier?.toLowerCase() === FORMAT_IDENTIFIER_EXTERNAL_WORKSHOP) && mediaWidth > MEDIA_TABLET_WIDTH
    ) {
     setShowMia(true);
    }
  }, [formatConfiguration, mediaWidth]);

  function miaStyle() {
    if(mediaWidth <= MEDIA_TABLET_WIDTH && showMia) {
      return {
        zIndex: 12
      }
    }
    return {
      bottom: miaPosition && mediaWidth <= MEDIA_TABLET_WIDTH ? "110px" : ""
    }
  }

  const closeMia = () => {
    setShowMia(false);
  };

  const openMia = useCallback(() => {
    setShowMia(true);
  }, []);

  if (!search.has(CONTENT_ID_QUERY)) {
    if (!storybook) {
      return <Navigate to={ROUTES.DASHBOARD.BASE} />;
    }
  }

  /**
   * Computes the right button label to visit external content
   * @param formatConfig the format configuration
   */
  function getExternalButtonLabel(
    formatConfig: ShallowEntity<FormatConfigurationWithMetaData>
  ) {
    let formatType = formatConfig?.configuration.format_type?.toLowerCase();
    let buttonLabel;
    switch (formatType) {
      case FORMAT_TYPE_VIDEO:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalVideo"
        });
        break;
      case FORMAT_TYPE_APP:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalApp"
        });
        break;
      case FORMAT_TYPE_CODING_CAMP:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalCodingCamp"
        });
        break;
      case FORMAT_TYPE_HACKATHON:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalHackathon"
        });
        break;
      case FORMAT_TYPE_INFORMATION_MATERIAL:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalInformationMaterial"
        });
        break;
      case FORMAT_TYPE_LAB:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalLab"
        });
        break;
      case FORMAT_TYPE_EDUCATIONAL_GAME:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalEducationalGame"
        });
        break;
      case FORMAT_TYPE_LEARNING_TOOL:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalLearningTool"
        });
        break;
      case FORMAT_TYPE_OFFLINE_ACTIVITIES:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalOfflineActivities"
        });
        break;
      case FORMAT_TYPE_ONLINE_COURSE:
        if(formatConfig.formatIdentifier === FORMAT_IDENTIFIER_EXTERNAL_WORKSHOP) {
          buttonLabel = intl.formatMessage({
            id: "CoursePage.ButtonExternalWorkshop"
          });
        } else if(formatConfig.formatIdentifier === FORMAT_IDENTIFIER_EXTERNAL_LESSON) {
          buttonLabel = intl.formatMessage({
            id: "CoursePage.ButtonExternalCourse"
          });
        } else {
          buttonLabel = intl.formatMessage({
            id: "CoursePage.ButtonInternalCourse"
          });
        }
        break;
      case FORMAT_TYPE_ONLINE_WORKSHOP:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalOnlineWorkshop"
        });
        break;
      case FORMAT_TYPE_PDF:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalPDF"
        });
        break;
      case FORMAT_TYPE_IN_PERSON_WORKSHOP:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalInPersonWorkshop"
        });
        break;
      case FORMAT_TYPE_SELF_TEST:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalSelfTest"
        });
        break;
      case FORMAT_TYPE_EDUCATIONAL_MATERIAL:
        buttonLabel = intl.formatMessage({
          id: "CoursePage.ButtonExternalEducationalMaterial"
        });
        break;
      default:
        buttonLabel = intl.formatMessage({ id: "CoursePage.ButtonDefault" });
        break;
    }
    return buttonLabel;
  }

  /**
   * Displays the format depending on the formatIdentifier
   * @param formatConfig the format configuration
   */
  function displayFormat(
    formatConfig: ShallowEntity<FormatConfigurationWithMetaData>
  ) {
    if (formatConfig.formatIdentifier === FORMAT_IDENTIFIER_EXTERNAL_LESSON
        || formatConfig.formatIdentifier === FORMAT_IDENTIFIER_EXTERNAL_WORKSHOP) {
      return (
        <div className={styles.externalCourse}>
          <div className={styles.externalCourseButtons}>
          <Button
            type={(showButton || courseFinished) ? ButtonType.SECONDARY : ButtonType.PRIMARY}
            label={getExternalButtonLabel(formatConfig)}
            onClick={() => openInNewTab()}
            icon={<OpenInNewIcon/>}
          />
          </div>
          {showButton &&
              <div className={styles.externalCourseButtons}>
                <Button
                    type={ButtonType.PRIMARY}
                    label={intl.formatMessage({id: "CoursePage.courseFinishButtonLabel"})}
                    onClick={() => finishCourse()}
                />
              </div>}
          {courseFinished &&
            <div className={styles.externalCourseFinished}>
              <span>{intl.formatMessage({ id: "CoursePage.courseFinished" })}</span>
            </div>
          }
        </div>
      );
    } else if (
      formatConfig.configuration.format_type?.toLowerCase() ===
      FORMAT_TYPE_VIDEO
    ) {
      return (
        <div className={styles.video}>
            <video width={800} controls={true}>
                <source
                  src={
                    getFormatBaseUrl(formatConfig) +
                    formatConfig.configuration.file_name
                  }
                />
            </video>
          {showButton &&
            <div className={styles.videoFinishButton}>
              <Button
                  flexible={ButtonFlexibility.STRETCH}
                  label={intl.formatMessage({id: "CoursePage.courseFinishButtonLabel"})}
                  onClick={() => finishCourse()}
              />
          </div>}
          {courseFinished &&
            <div className={styles.videoFinished}>
              <span>{intl.formatMessage({ id: "CoursePage.courseFinished" })}</span>
            </div>
          }
        </div>
      );
    } else {
      return (
        <div className={styles.internalCourse}>
          <div className={styles.landscapeInfoBanner}>
            <NotificationBanner type={NotificationBannerType.INFO} message={intl.formatMessage({id: "CoursePage.landscapeInfoBanner"})}/>
          </div>
              <div className={styles.internalCourseButton}>
                <Button
                    type={(showButton || courseFinished) ? ButtonType.SECONDARY : ButtonType.PRIMARY}
                    label={getExternalButtonLabel(formatConfig)}
                    onClick={() => openInNewTab(formatConfig)}
                    icon={<OpenInNewIcon/>}
                />
              </div>
              <iframe
                src={getFormatPreviewSource(formatConfig)}
                title={"internalCourse"}
              />
          {showButton &&
              <div className={styles.internalCourseFinishButton}>
                  <Button
                      flexible={ButtonFlexibility.STRETCH}
                      label={intl.formatMessage({id: "CoursePage.courseFinishButtonLabel"})}
                      onClick={() => finishCourse()}
              />
          </div>}
          {courseFinished &&
            <div className={styles.internalCourseFinished}>
              <span>{intl.formatMessage({ id: "CoursePage.courseFinished" })}</span>
            </div>
          }
        </div>
      );
    }
  }

  const styleOverlay = mediaWidth <= MEDIA_TABLET_WIDTH ? {
    display: "block"
  } : {}

  const styleWindow = mediaWidth <= MEDIA_MOBILE_WIDTH ? {
    padding: "15px"
  } : {}

  const onModalClose = () =>  {
    setIsOpen(false);
    setModalClose(true);
    setShowButton(true);
    setCourseStarted(true);
    if(formatConfiguration) {
      if(formatConfiguration.formatIdentifier === FORMAT_IDENTIFIER_EXTERNAL_LESSON || formatConfiguration.formatIdentifier === FORMAT_IDENTIFIER_EXTERNAL_WORKSHOP) {
        openInNewTabFunc();
      } else if (formatConfiguration.configuration.format_type?.toLowerCase() !== FORMAT_TYPE_VIDEO) {
        mediaWidth <= MEDIA_MOBILE_WIDTH && openInNewTabFunc(formatConfiguration);
      }
    }
  }

  const breadcrumbTabs: Array<BreadcrumbTabProps> = [
    {
      label: intl.formatMessage({ id: "Dashboard.name" }),
      onClick: () => navigate(ROUTES.DASHBOARD.BASE)
    },
    { label: lesson ? lesson.name : "" }
  ];

  return (
    <div className={styles.CoursePage}>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs} />
      </div>
      <div className={styles.courseIntro}>
        {id && <CourseIntroWrapper
            lessonId={id}
            workshop={formatConfiguration?.formatIdentifier === FORMAT_IDENTIFIER_EXTERNAL_WORKSHOP} />
        }
      </div>
      <Modal styleWindow={{ margin: "0 10px" }}
             isOpen={isOpen}
             backgroundColorWindow={MODAL_BACKGROUND_WINDOW_COLOR}
             onClose={onModalClose}
             customCloseIcon={<CloseRoundedIcon style={{color: CLOSE_ICON_COLOR}} />}
      >
        <div className={styles.confirmationModal}>
          <div className={styles.modalHeaders}>
            <h4>{intl.formatMessage({id: "CoursePage.modalTitle"})}</h4>
            <p>{intl.formatMessage({id: "CoursePage.modalLineOne"})}</p>
            <p>{intl.formatMessage({id: "CoursePage.modalLineTwo"})}</p>
          </div>
          <div className={styles.modalButtons}>
            <Button type={ButtonType.SECONDARY}
                    width={"143px"}
                    height={"48px"}
                    label={intl.formatMessage({id: "CoursePage.closeButton"})}
                    onClick={onModalClose}/>
            <Button type={ButtonType.PRIMARY}
                    width={"143px"}
                    height={"48px"}
                    label={intl.formatMessage({id: "CoursePage.toRegisterButton"})}
                    onClick={() => navigate(ROUTES.REGISTER.BASE)}/>
          </div>
        </div>
      </Modal>
      <div className={styles.courseContent}>
        {
            formatConfiguration?.formatIdentifier !== FORMAT_IDENTIFIER_EXTERNAL_LESSON
            && formatConfiguration?.formatIdentifier !== FORMAT_IDENTIFIER_EXTERNAL_WORKSHOP
            && !showButton
            && !courseStarted
            && !courseFinished
            && !modalClose
            && ((formatConfiguration?.configuration.format_type.toLowerCase() === FORMAT_TYPE_VIDEO && (mediaWidth <= MEDIA_MOBILE_WIDTH)) || mediaWidth > MEDIA_MOBILE_WIDTH)
            &&
            <>
              <div className={classNames(styles.overlay, formatConfiguration?.configuration.format_type.toLowerCase() === FORMAT_TYPE_VIDEO && styles.videoOverlay)}>{}</div>
              <div className={styles.overlayButton}>
                <Button
                    label={intl.formatMessage({id: "CoursePage.courseStartButtonLabel"})}
                    onClick={() => {
                      if(loggedIn) {
                        !courseStarted && !showButton && addStartedCourse();
                        setShowButton(true);
                        setCourseStarted(true);
                      } else {
                        registerModalShow();
                      }
                    }}
                />
              </div>
            </>
        }
        {formatConfiguration && displayFormat(formatConfiguration)}
        <div className={styles.modal}>
          <Modal
              onClose={() => setModal(false)}
              backgroundColorWindow={MODAL_BACKGROUND_WINDOW_COLOR}
              isOpen={modal}
              customCloseIcon={<CloseRoundedIcon style={{color: CLOSE_ICON_COLOR}} />}
              styleWindow={styleWindow}
              styleOverlay={styleOverlay}
              modalPosition={"static"}
          >
            <CourseModalDisplay />
          </Modal>
        </div>
      </div>
      {initialized && (
        <div style={miaStyle()} className={styles.miaFixed}>
          <MiaButton
            openMia={openMia}
            visible={!showMia}
            miaSettings={miaSettings}
            direction={MiaDirection.LEFT}
            formatIdentifier={formatConfiguration?.formatIdentifier}
          />
          <div className={styles.miaHandler}>
            <MiaHandler
              onClose={closeMia}
              open={showMia}
              onOpen={openMia}
              miaDirection={MiaDirection.LEFT}
              formatIdentifier={formatConfiguration?.formatIdentifier}
              miaSettings={miaSettings}
              courseName={lesson?.name || ''}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursePage;
