import React, {FunctionComponent, useEffect, useState} from 'react';
import {ROUTES} from "../../utils/routes";
import {useIntl} from 'react-intl';
import styles from './JobmatchPage.module.scss';
import {loadJsonFile, MEDIA_MOBILE_WIDTH} from '../../utils/helper';
import {BreadcrumbGroup, BreadcrumbTabProps} from '@dis/colors-components';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, Outlet} from 'react-router-dom';
import {selectMediaQueries} from "../../redux/mediaQueriesSlice";
import {
  selectCurrentStep,
  setAllCompetencies,
  setAllInterests,
  setAllJobs,
  setImagesOfInterests, setPhotos
} from "../../redux/jobmatchSlice";

export interface JobmatchStepProps {
  prev?: string;
  next?: string;
  step?: number
}

export const fetchImageAsBase64 = async (url: string | URL | Request) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    if (blob.type === "image/webp") {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Base64 encoded string
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }
  });
};

const JobmatchPage: FunctionComponent = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {mediaWidth} = useSelector(selectMediaQueries);

  const currentStep = useSelector(selectCurrentStep);

  /* newly added Mia handling */
  const [scrollSpeed, setScrollSpeed] = useState<number>(0.75);

  useEffect(() => {
    //set scroll speed
    if (mediaWidth <= MEDIA_MOBILE_WIDTH) setScrollSpeed(1);
    else setScrollSpeed(0.75);
  }, [scrollSpeed, mediaWidth])

  const breadcrumbTabs: Array<BreadcrumbTabProps> = [
    {label: intl.formatMessage({id: 'Dashboard.name'}), onClick: () => navigate(ROUTES.DASHBOARD.BASE)},
    {label: intl.formatMessage({id: `Jobmatch.step${currentStep}.breadcrumb`})}
  ];

  useEffect(() => {
    async function loadData() {
      let response = await loadJsonFile(`${process.env.PUBLIC_URL}/content/jobmatch/jobmatch-content.json`);
      const jsonData = await response.json();
      const interestsRandomized = jsonData.interests.sort(() => Math.random() - 0.5);
      dispatch(setAllInterests(interestsRandomized));
      dispatch(setAllJobs(jsonData.jobs));
      dispatch(setAllCompetencies(jsonData.competencies));
      for (const interest of jsonData.interests) {
        const base64img = await fetchImageAsBase64(interest.image);
        dispatch(setImagesOfInterests({id: interest.id, imageData: base64img}));
      }
      for (const job of jsonData.jobs) {
        if (job.mentor.photo !== "") {
          const base64photo = await fetchImageAsBase64(job.mentor.photo);
          dispatch(setPhotos({id: job.id, photoData: base64photo}))
        }
      }
    }
    loadData().catch(console.error);
  }, [dispatch]);


  return (
    <div className={styles.JobmatchPage}>
      <div className={styles.breadcrumb}>
        <BreadcrumbGroup breadcrumbTabs={breadcrumbTabs}/>
      </div>
      <div className={styles.jobmatchFrame}>
        <Outlet/>
      </div>
    </div>
  )

}

export default JobmatchPage;
