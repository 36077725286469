import styles from './PantsCustomizations.module.scss';
import {CustomizationOptionPreview, Customizations, CustomizationsStyles} from "./Customizations";


export enum PantsCustomizationsIds {
    DEFAULT = "pants_0",
    TYPE_1 = "pants_1",
    TYPE_2 = "pants_2",
}

const PantsCustomizationPreviews: CustomizationOptionPreview<PantsCustomizationsIds>[] = [
    {id: PantsCustomizationsIds.DEFAULT, previewColor1: "#86552f", previewColor2: "#5c9677"},
    {id: PantsCustomizationsIds.TYPE_1, previewColor1: "#86552f", previewColor2: "#195f73"},
    {id: PantsCustomizationsIds.TYPE_2, previewColor1: "#5b5a59", previewColor2: "#404242"}
]

const PantsStyles: CustomizationsStyles<PantsCustomizationsIds> = {
    [PantsCustomizationsIds.DEFAULT]: styles.pants_0,
    [PantsCustomizationsIds.TYPE_1]: styles.pants_1,
    [PantsCustomizationsIds.TYPE_2]: styles.pants_2,
}

const PantsCustomizations: Customizations<PantsCustomizationsIds> = {
    previews: PantsCustomizationPreviews,
    styles: PantsStyles,
}

export default PantsCustomizations;
