import React, {FunctionComponent} from 'react';
import styles from './MiaCustomization.module.scss';
import customizations from './Customizations/Customizations.module.scss';
import poses from './Poses/Poses.module.scss';
import {ReactComponent as MiaSVG} from '../../../assets/images/mia/mia-customizable.svg';
import classNames from "classnames";
import JacketCustomizations, {JacketCustomizationsIds} from './Customizations/JacketCustomizations';
import EyesCustomizations, {EyesColorCustomizationsIds} from "./Customizations/EyesColorCustomizations";
import SkinToneCustomizations, {SkinToneCustomizationsIds} from "./Customizations/SkinToneCustomizations";
import HairColorCustomizations, {HairColorCustomizationsIds} from "./Customizations/HairColorCustomizations";
import {MiaPose} from "./Poses/Poses";
import AccessoriesCustomizations, {AccessoriesCustomizationsIds} from "./Customizations/AccessoriesCustomizations";
import PantsCustomizations, {PantsCustomizationsIds} from "./Customizations/PantsCustomizations";


export interface MiaCustomizationProps {
    pose?: MiaPose,
    skinTone?: SkinToneCustomizationsIds,
    hairColor?: HairColorCustomizationsIds,
    eyesColor?: EyesColorCustomizationsIds,
    jacketColor?: JacketCustomizationsIds,
    pantsColor?: PantsCustomizationsIds,
    accessoriesColor?: AccessoriesCustomizationsIds,
}

export const MiaCustomization: FunctionComponent<MiaCustomizationProps> = ({
                                                                               pose,
                                                                               skinTone,
                                                                               hairColor,
                                                                               eyesColor,
                                                                               jacketColor,
                                                                               pantsColor,
                                                                               accessoriesColor,
                                                                           }) => {

    const miaPose = () => {
        switch (pose) {
            case MiaPose.BOOK:
                return poses.pose_1;
            case MiaPose.CELEBRATING:
                return poses.pose_2;
            case MiaPose.POCKET:
                return poses.pose_3;
            default:
                return poses.pose_0;
        }
    }

    const miaCustomization = () => {
        const default_customization = customizations.default_customization;

        let hair = hairColor ? HairColorCustomizations.styles[hairColor] : undefined;
        let skin = skinTone ? SkinToneCustomizations.styles[skinTone] : undefined;
        let eyes = eyesColor ? EyesCustomizations.styles[eyesColor] : undefined;
        let jacket = jacketColor ? JacketCustomizations.styles[jacketColor] : undefined;
        let pants = pantsColor ? PantsCustomizations.styles[pantsColor] : undefined;
        let accessories = accessoriesColor ? AccessoriesCustomizations.styles[accessoriesColor] : undefined;

        return classNames([default_customization, hair, skin, eyes, jacket, pants, accessories])
    }

    return (
        <MiaSVG className={classNames(styles.mia, miaPose(), miaCustomization())}/>
    );
};
