import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {FunctionComponent} from "react";
import styles from "./JobTile.module.scss";
import classNames from "classnames";

export interface JobTileProps {
  onClick?: (event: React.MouseEvent) => void;
  title: string;
  description: string;
  rating: number;
  selected?: boolean;
}

export const JobTile: FunctionComponent<JobTileProps> = ({
                                                           onClick,
                                                           title,
                                                           description,
                                                           rating,
                                                           selected = false
                                                         }) => {
  return (
    <div className={classNames(styles.JobTile, selected ? styles.selected : styles.unSelected)} onClick={onClick}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.rating}>
          <CheckCircleIcon className={styles.icon}/>{`passt zu ${rating} % zu dir`}
      </div>
      <div className={styles.text}>
        {description}
      </div>
    </div>
  )
}
