import React, {FunctionComponent, useEffect} from 'react';
import {useNavigate, To} from "react-router-dom";
import {Button, SelectableTile, TileContentsProps} from "@dis/colors-components";
import {JobmatchStepProps} from "./JobmatchPage";
import {useDispatch, useSelector} from 'react-redux';
import {
  selectAllInterests,
  selectAllJobs,
  selectChosenInterestsLabels,
  setChosenInterestsLabels,
  setCurrentStep,
  setFilteredJobs
} from "../../redux/jobmatchSlice";
import styles from './JobmatchPage.module.scss';

const JobmatchStep1: FunctionComponent<JobmatchStepProps> = ({step, next}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentStep(step))
  }, [step, dispatch]);

  const allInterests = useSelector(selectAllInterests);
  const allJobs = useSelector(selectAllJobs);
  const extractedInterestIdsFromAllJobs = new Set(allJobs.flatMap(job => job.interests));
  const interestsUsedByAllJobs = allInterests.filter(interest =>
    interest.id !== undefined && extractedInterestIdsFromAllJobs.has(interest.id)
  );

  const chosenInterestsLabels = useSelector(selectChosenInterestsLabels);
  let selectedInterests: TileContentsProps[];

  const interestsOnChange = (
    event: React.MouseEvent<HTMLElement>, newInterest: string[]
  ) => dispatch(setChosenInterestsLabels(newInterest));

  const calculateJobsToShow = ()=> {
    const idsOfSelectedInterests = selectedInterests.map(s => s.id);
    let filteredJobs = allJobs.filter(job =>
      job.interests.some(anyInterestIdOfAllJobs => idsOfSelectedInterests.includes(anyInterestIdOfAllJobs))
    );
    dispatch(setFilteredJobs(filteredJobs));
  }

  const onNextClick = (next: To) => {
    selectedInterests = interestsUsedByAllJobs.filter(interest =>
      chosenInterestsLabels.includes(interest.label)
    );
    calculateJobsToShow();
    navigate(next);
  };

  return (
    <>
      <h4>Was interessiert dich?<br/>Wähle 1–5 Interessen aus, indem du die passenden Bilder wählst.</h4>
      <div className={styles.stepContent}>
        <div className={styles.selectableTile}>
          {interestsUsedByAllJobs &&
            <SelectableTile tileContents={interestsUsedByAllJobs} onChange={interestsOnChange} value={chosenInterestsLabels}/>
          }
        </div>
      </div>
      <div className={styles.buttons}>
        {next && <Button label={"Weiter zu deinen Fähigkeiten"}
                         onClick={() => onNextClick(next)}
                         className={styles.buttonNext}
                         disabled={chosenInterestsLabels.length === 0 || chosenInterestsLabels.length > 5}
        />}
      </div>
    </>
  )
}

export default JobmatchStep1;
