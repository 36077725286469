import React, {FunctionComponent, useEffect, useState} from "react";
import styles from "./Header.module.scss";
import logo from "../../assets/images/logo.png";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/routes";
import {Button, ButtonType, MenuButton, UserAvatar} from "@dis/colors-components";
import {useIntl} from "react-intl";
import {useKeycloak} from "@react-keycloak/web";
import classNames from "classnames";
import {HamburgerSpring} from "react-animated-burgers";
import HomeIcon from "@mui/icons-material/Home";
import {MenuDropdown, OptionProps} from "../../components/MenuDropdown/MenuDropdown";
import {useDispatch, useSelector} from "react-redux";
import {selectUserAvatar, userAvatarSet} from "../../redux/userAvatarSlice";
import {selectUserSettings} from "../../redux/userSettingsSlice";
import {SCOPE_USER_AVATAR, userFilesApi} from "../../api/UserFilesApi";
import {fetchRecommendations} from "../../redux/recommendationSlice";
import {selectMediaQueries} from "../../redux/mediaQueriesSlice";
import {MEDIA_TABLET_WIDTH} from "../../utils/helper";
import {selectUserLoggedIn, selectUserProfile} from "../../redux/userLoggedInSlice";

export interface HeaderProps {
  isVisible?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({isVisible}) => {

  const navigate = useNavigate();

  const {keycloak, initialized} = useKeycloak();

  const dispatch = useDispatch();

  const intl = useIntl();
  const location = useLocation().pathname;

  const [expanded, setExpanded] = useState<boolean>(isVisible ? isVisible : false);

  const routes = [ROUTES.REGISTER.BASE, ROUTES.REGISTER.PERSONALIZATION];

  const loggedIn = useSelector(selectUserLoggedIn);
  let userSettings = useSelector(selectUserSettings);
  const [nickname, setNickname] = useState(userSettings.userName || "");
  const [email, setEmail] = useState("");
  const uploadedImage = useSelector(selectUserAvatar);
  const {mediaWidth, device} = useSelector(selectMediaQueries);
  const userProfile = useSelector(selectUserProfile);

  useEffect(() => {
    async function loadFiles() {
      let userId = null;
      if (loggedIn) {
        userId = userProfile.sub;
        dispatch(fetchRecommendations({userId: userId, device: device}));
      }

      if (userId) {
        const receivedImage = await userFilesApi.getImage(userId, SCOPE_USER_AVATAR);
        if (receivedImage !== undefined) {
          dispatch(userAvatarSet(receivedImage));
        }
      }
    }

    loadFiles();
  }, [dispatch, loggedIn, userProfile, device]);

  useEffect(() => {
    if (loggedIn) {
      if (nickname === "") setNickname(userProfile.nickname);
      setEmail(userProfile.email);
    }
  }, [loggedIn, nickname, userProfile]);

  function determineStyle() {
    let classes = [styles.headerWrapper];
    if (expanded) {
      classes.push(styles.expanded);
    }
    return classNames(classes);
  }

  useEffect(() => {
    setExpanded(false);
  }, [isVisible]);

  const reloadPage = () => {
    navigate(ROUTES.DASHBOARD.BASE);
    if (location === ROUTES.DASHBOARD.BASE) {
      window.location.reload();
    }
  };

  const onLoginButtonClick = () => {
    localStorage.setItem('registration', '0');
    if (keycloak) {
      window.location.assign(keycloak.createLoginUrl());
      if (mediaWidth > MEDIA_TABLET_WIDTH) {
        localStorage.setItem("show_mia", 'true');
        localStorage.setItem("dashboard_first_visit", 'false');
      }
    }
  };

  const options: Array<OptionProps> = [
    {
      name: intl.formatMessage({id: "Header.initiative"}),
      onClick: () => navigate(ROUTES.DASHBOARD.INITIATIVE)
    },
    {
      name: intl.formatMessage({id: "Header.blog"}),
      onClick: () => navigate(ROUTES.DASHBOARD.BLOG.BASE)
    },
    {
      name: intl.formatMessage({id: "Header.events"}),
      onClick: () => navigate(ROUTES.DASHBOARD.EVENTS)
    },
    {
      name: intl.formatMessage({id: "Header.media"}),
      onClick: () => navigate(ROUTES.DASHBOARD.MEDIA)
    },
    {
      name: intl.formatMessage({id: "Header.handout"}),
      onClick: () => navigate(ROUTES.DASHBOARD.HANDOUT)
    },
    {
      name: intl.formatMessage({id: "Header.jobmatch"}),
      onClick: () => navigate(ROUTES.DASHBOARD.JOBMATCH.INDEX)
    },
    {
      name: intl.formatMessage({id: "Header.newsletter"}),
      onClick: () => navigate(ROUTES.DASHBOARD.NEWSLETTER)
    },
    {
      name: intl.formatMessage({id: "Header.contact"}),
      onClick: () => navigate(ROUTES.DASHBOARD.CONTACT)
    }
  ];

  const checkName = () => {
    if (userSettings.userName) setNickname(userSettings.userName);
  }

  const logoutOnClick = () => {
    if (keycloak) {
      navigate(ROUTES.DASHBOARD.BASE);
      keycloak.logout();
    }
  };

  const BAR_COLOR = "#3BB4B4";

  return (
    <div className={determineStyle()}>
      <div className={styles.header}>
        <div className={styles.ycg}>
          <div onClick={reloadPage}>
            <img className={styles.logo} src={logo} alt={intl.formatMessage({id: "Header.logoAlt"})}/>
          </div>
        </div>
        {mediaWidth <= MEDIA_TABLET_WIDTH &&
          <div className={styles.hamburgerButton}>
            <HamburgerSpring isActive={expanded} buttonWidth={36} toggleButton={() => setExpanded(!expanded)}
                             barColor={BAR_COLOR}/>
          </div>
        }
        {loggedIn && mediaWidth > MEDIA_TABLET_WIDTH ?
          <div className={styles.loggedInMenu}>
            <div className={styles.dropdownMenu}>
              <div>{intl.formatMessage({id: "Header.menu"})}</div>
              <div className={styles.dropdownContainer}>
                <MenuDropdown
                  options={options}
                  relativePosition={{x: -50, y: 5}}
                  zIndex={10}
                />
              </div>
            </div>
            <div className={styles.homeIcon} onClick={reloadPage}>
              <HomeIcon fontSize={"large"}/>
            </div>
          </div>
          : <div className={styles.menu}>
            {!loggedIn && initialized && routes.every(route => location !== route) &&
              <div className={classNames(styles.menuEntry, styles.loginMenuEntry)}>
                <MenuButton onClick={() => {
                  setExpanded(false);
                  onLoginButtonClick();
                }}
                            label={intl.formatMessage({id: "Header.login"})}/>
              </div>
            }
            {loggedIn && initialized && mediaWidth <= MEDIA_TABLET_WIDTH &&
              <>
                <div className={styles.menuEntry}>
                  <MenuButton onClick={() => {
                    setExpanded(false);
                    navigate(ROUTES.DASHBOARD.BASE);
                  }}
                              label={intl.formatMessage({id: "Header.dashboard"})}
                              selected={location === ROUTES.DASHBOARD.BASE}/>
                </div>
                <div className={styles.menuEntry}>
                  <MenuButton onClick={() => {
                    setExpanded(false);
                    navigate(ROUTES.DASHBOARD.USERAVATAR.LEARNING_OFFERS);
                  }}
                              label={intl.formatMessage({id: "Header.useravatar.learningoffers"})}
                              selected={location === ROUTES.DASHBOARD.USERAVATAR.LEARNING_OFFERS}/>
                </div>
                <div className={styles.menuEntry}>
                  <MenuButton onClick={() => {
                    setExpanded(false);
                    navigate(ROUTES.DASHBOARD.USERAVATAR.PROFILE);
                  }}
                              label={intl.formatMessage({id: "Header.useravatar.profile"})}
                              selected={location === ROUTES.DASHBOARD.USERAVATAR.PROFILE}/>
                </div>
              </>

            }
            <div className={styles.menuEntry}>
              <MenuButton onClick={() => {
                setExpanded(false);
                navigate(ROUTES.DASHBOARD.INITIATIVE);
              }}
                          label={intl.formatMessage({id: "Header.initiative"})}
                          selected={location === ROUTES.DASHBOARD.INITIATIVE}/>
            </div>
            <div className={styles.menuEntry}>
              <MenuButton onClick={() => {
                setExpanded(false);
                navigate(ROUTES.DASHBOARD.BLOG.BASE);
              }}
                          label={intl.formatMessage({id: "Header.blog"})}
                          selected={location === ROUTES.DASHBOARD.BLOG.BASE || location === ROUTES.DASHBOARD.BLOG.BLOG_VIEW}/>
            </div>
            <div className={styles.menuEntry}>
              <MenuButton onClick={() => {
                setExpanded(false);
                navigate(ROUTES.DASHBOARD.EVENTS);
              }}
                          label={intl.formatMessage({id: "Header.events"})}
                          selected={location === ROUTES.DASHBOARD.EVENTS}/>
            </div>
            <div className={styles.menuEntry}>
              <MenuButton onClick={() => {
                setExpanded(false);
                navigate(ROUTES.DASHBOARD.MEDIA);
              }}
                          label={intl.formatMessage({id: "Header.media"})}
                          selected={location === ROUTES.DASHBOARD.MEDIA}/>
            </div>
            <div className={styles.menuEntry}>
              <MenuButton onClick={() => {
                setExpanded(false);
                navigate(ROUTES.DASHBOARD.HANDOUT);
              }}
                          label={intl.formatMessage({id: "Header.handout"})}
                          selected={location === ROUTES.DASHBOARD.HANDOUT}/>
            </div>
            <div className={styles.menuEntry}>
              <MenuButton onClick={() => {
                setExpanded(false);
                navigate(ROUTES.DASHBOARD.JOBMATCH.INDEX);
              }}
                          label={intl.formatMessage({id: "Header.jobmatch"})}
                          selected={location === ROUTES.DASHBOARD.JOBMATCH.INDEX}/>
            </div>
            <div className={styles.menuEntry}>
              <MenuButton onClick={() => {
                setExpanded(false);
                navigate(ROUTES.DASHBOARD.NEWSLETTER);
              }}
                          label={intl.formatMessage({id: "Header.newsletter"})}
                          selected={location === ROUTES.DASHBOARD.NEWSLETTER}/>
            </div>
            <div className={styles.menuEntry}>
              <MenuButton onClick={() => {
                setExpanded(false);
                navigate(ROUTES.DASHBOARD.CONTACT);
              }}
                          label={intl.formatMessage({id: "Header.contact"})}
                          selected={location === ROUTES.DASHBOARD.CONTACT}/>
            </div>
            {loggedIn && initialized && mediaWidth <= MEDIA_TABLET_WIDTH &&
              <div className={styles.menuEntry}>
                <MenuButton onClick={logoutOnClick}
                            label={intl.formatMessage({id: "Header.useravatar.logout"})}/>
              </div>
            }
          </div>
        }
        {initialized && routes.every(route => location !== route) &&
          <div className={styles.login}>
            {
              loggedIn && mediaWidth > MEDIA_TABLET_WIDTH ?
                <div onClick={checkName}>
                  <UserAvatar primaryText={email} secondaryText={nickname} imgSize={"30px"} userAvatar={uploadedImage}
                              toolTipText={"Klicken zum Ausklappen"}>
                    <span onClick={() => {
                      navigate(ROUTES.DASHBOARD.USERAVATAR.LEARNING_OFFERS);
                    }}> {intl.formatMessage({id: "Header.useravatar.learningoffers"})} </span>
                    <span onClick={() => {
                      navigate(ROUTES.DASHBOARD.USERAVATAR.PROFILE);
                    }}> {intl.formatMessage({id: "Header.useravatar.profile"})} </span>
                    <span onClick={logoutOnClick}>
                  {intl.formatMessage({id: "Header.useravatar.logout"})}
                </span>
                  </UserAvatar>
                </div>
                : <div className={styles.loginButton}>
                  <Button
                    type={ButtonType.SECONDARY}
                    width={"188px"} height={"48px"}
                    label={intl.formatMessage({id: "Header.login"})}
                    onClick={onLoginButtonClick}
                  />
                </div>
            }
          </div>}
      </div>
      <div className={styles.clickAway} onClick={() => setExpanded(false)}/>
    </div>
  );
};
export default Header;
