import React, {FunctionComponent, useEffect, useState} from 'react';
import {To, useNavigate} from "react-router-dom";
import {Button, ButtonType} from "@dis/colors-components";
import {JobmatchStepProps} from "./JobmatchPage";
import {useDispatch, useSelector} from 'react-redux';
import {selectWeightedResultingJobs, setChosenJobId, setCurrentStep} from "../../redux/jobmatchSlice";
import styles from './JobmatchPage.module.scss';
import {rateValues} from "./JobmatchStep2";
import {JobTile} from "../../components/JobTile/JobTile";

const JobmatchStep3: FunctionComponent<JobmatchStepProps> = ({step, prev, next}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedJob, setSelectedJob] = useState<string | null>(null);

  useEffect(() => {
    dispatch(setCurrentStep(step))
  }, [step, dispatch]);

  const weightedResultingJobs = useSelector(selectWeightedResultingJobs);

  const onNextClick = (next: To) => {
    dispatch(setChosenJobId(selectedJob));
    navigate(next);
  };

  const jobTopMatches = weightedResultingJobs.slice(0,3);

  const setSelected = (jobId: string) => {
    if (selectedJob === null || selectedJob !== jobId) {
      setSelectedJob(jobId)
    }
  };

  let top3Jobs = jobTopMatches.map(job => {
    const maximumWeight = rateValues.bonusMult * rateValues.superlike + (job.competencies.length - 1) * rateValues.superlike;
    const matchPercent = Math.round(100 * job.totalWeight / maximumWeight);
    if (jobTopMatches.length === 1) {setSelected(job.id)}
    return (
      <JobTile
        key={job.id}
        onClick={() => setSelected(job.id)}
        title={job.title}
        description={job.shortDescription}
        rating={matchPercent}
        selected={jobTopMatches.length === 1 || selectedJob === job.id}
      />)
  });

  return (
    <>
      <h3 className={styles.hNoMargin}>Hier ist dein Berufe-Match!</h3>
      <h4>Diese Berufe passen am besten zu deinen Interessen und Fähigkeiten.<br/>Wähle den Beruf, über den du mehr erfahren möchtest und bestätige deine Auswahl mit „Mehr über den Beruf erfahren.“</h4>
      <div className={styles.stepContent}>
        {top3Jobs}
      </div>
      <div className={styles.buttons}>
        {prev && <Button label={"Zurück zu deinen Fähigkeiten"}
                         onClick={() => navigate(prev)}
                         type={ButtonType.SECONDARY}/>}
        {next && <Button label={"Mehr über den Beruf erfahren"}
                         onClick={() => onNextClick(next)}
                         disabled={jobTopMatches.length !== 1 && selectedJob === null}/>}
      </div>
    </>
  )
}

export default JobmatchStep3;
