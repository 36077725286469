import React, {FunctionComponent, useEffect, useState} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import styles from "./App.module.scss";
import {ROUTES} from "./utils/routes";
import Dashboard from "./views/Dashboard/Dashboard";
import InitiativePage from "./views/InitiativePage/InitiativePage";
import Header from "./building-blocks/Header/Header";
import {Footer} from "./building-blocks/Footer/Footer";
import {IntlProvider} from "react-intl";
import DEU from "./lang/DEU.json";
import ENG from "./lang/ENG.json";
import {selectLanguage} from "./redux/languageSlice";
import {useDispatch, useSelector} from "react-redux";
import Blog from "./views/Blog/Blog";
import MediaPage from "./views/MediaPage/MediaPage";
import BlogView from "./views/Blog/BlogView/BlogView";
import EventsPage from "./views/EventsPage/EventsPage";
import CoursePage from "./views/CoursePage/CoursePage";
import classNames from "classnames";
import CodingChallenge from "./views/CodingChallenge/CodingChallenge";
import {MEDIA_MOBILE_WIDTH, MEDIA_TABLET_WIDTH, ScrollToTop, wrapWithThemeProvider} from "./utils/helper";
import MiaCustomizationPage from "./views/MiaCustomizationPage/MiaCustomizationPage";
import {fetchLessons, fetchUserSettings} from "./redux/fetchActions";
import {useKeycloak} from "@react-keycloak/web";
import PrivacyNoticePage from "./views/PrivacyNoticePage/PrivacyNoticePage";
import LegalNoticePage from "./views/LegalNoticePage/LegalNoticePage";
import {LegalPrivacyNoticeBar} from "./building-blocks/LegalPrivacyNoticeBar/LegalPrivacyNoticeBar";
import ContactPage from "./views/ContactPage/ContactPage";
import RegistrationPage from "./views/RegistrationPage/RegistrationPage";
import RegistrationPersonalizationPage from "./views/RegistrationPersonalizationPage/RegistrationPersonalizationPage";
import TypeformFeedback from "./views/TypeformFeedback/TypeformFeedback";
import LearningOffers from "./views/LearningOffers/LearningOffers";
import ProfilePage from "./views/ProfilePage/ProfilePage";
import ContactPageSent from "./views/ContactPage/ContactPageSent/ContactPageSent";
import {DeviceSet, MediaWidthSet, selectMediaQueries, DeviceNames} from "./redux/mediaQueriesSlice";
import {v4 as uuidv4} from "uuid";
import {selectUserLoggedIn, userLoggedInSet, userProfileSet} from "./redux/userLoggedInSlice";
import HandoutPage from "./views/HandoutPage/HandoutPage";
import {UserInterestsState} from "./redux/userInterestsSlice";
import OrderPage from "./views/OrderPage/OrderPage";
import OrderPageSent from "./views/OrderPage/OrderPageSent/OrderPageSent";
import NewsletterPage from "./views/Newsletter/NewsletterPage";
import JobmatchPage from "./views/JobmatchPage/JobmatchPage";
import JobmatchStep1 from "./views/JobmatchPage/JobmatchStep1";
import JobmatchStep2 from "./views/JobmatchPage/JobmatchStep2";
import JobmatchStep3 from "./views/JobmatchPage/JobmatchStep3";
import JobmatchStep4 from "./views/JobmatchPage/JobmatchStep4";
import JobmatchStep5 from "./views/JobmatchPage/JobmatchStep5";
import JobmatchStep6 from "./views/JobmatchPage/JobmatchStep6";
import {selectCurrentStep} from "./redux/jobmatchSlice";

export const domainGroupId = '87c13977-47a9-44fc-9401-e1a029aca48b';
const MIA_POSITION = 200;

const App: FunctionComponent = () => {

  const dispatch = useDispatch();
  let selectedLanguage = useSelector(selectLanguage);

  const {keycloak, initialized} = useKeycloak();
  const loggedIn = useSelector(selectUserLoggedIn);
  const {mediaWidth} = useSelector(selectMediaQueries);

  if (localStorage.getItem("uuid") === null && !loggedIn) {
    localStorage.setItem("uuid", uuidv4());
  }

  function languageMessages() {
    switch (selectedLanguage) {
      case "DEU":
        return DEU;
      case "ENG":
        return ENG;
      default:
        return DEU;
    }
  }

  let base = "";
  const pathName = window.location.pathname;
  const APP_NAME = "portal";
  const paths = pathName.split(APP_NAME);
  if (paths.length > 1) {
    base = paths[0] + APP_NAME;
  }

  const [hidden, setHidden] = useState<boolean>(false);
  const [miaPosition, setMiaPosition] = useState<boolean>(false);

  useEffect(() => {
    if (!localStorage.getItem('course_first_visit')) {
      localStorage.setItem('course_first_visit', 'true');
    }
  }, [])

  useEffect(() => {
    if (!loggedIn && localStorage.getItem('show_mia') === 'true') {
      localStorage.setItem('show_mia', 'false');
      localStorage.setItem('dashboard_first_visit', 'false');
    } else {
      localStorage.removeItem('show_mia');
      localStorage.removeItem('dashboard_first_visit');
    }
  }, [loggedIn])

  useEffect(() => {

    let fileName = "";
    const routeName = '/' + (pathName.split('/').filter(el => el !== '').pop());

    switch (routeName) {
      case ROUTES.HANDOUT.CLUB_DER_CODERINNEN:
        fileName = "YCG_RZ_Digital_Methode_Club_Der_Coderinnen.pdf";
        break;
      case ROUTES.HANDOUT.DRUNTER_UND_DRUEBER:
        fileName = "YCG_RZ_Digital_Methode_Drunter_Und_Drüber.pdf";
        break;
      case ROUTES.HANDOUT.EXPERTINNEN_UNTER_SICH:
        fileName = "YCG_RZ_Digital_Methode_Expertinnen_Unter_Sich.pdf";
        break;
      case ROUTES.HANDOUT.GEHEIME_SPRACHEN:
        fileName = "YCG_RZ_Digital_Methode_Geheime_Sprachen.pdf";
        break;
      case ROUTES.HANDOUT.ICH_MAGS:
        fileName = "YCG_RZ_Digital_Methode_Ich_Mags.pdf";
        break;
      case ROUTES.HANDOUT.LUEGE_ODER_WAHRHEIT:
        fileName = "YCG_RZ_Digital_Methode_Lüge_Oder_Wahrheit.pdf";
        break;
      case ROUTES.HANDOUT.OBS_ZUSAMMENPASST:
        fileName = "YCG_RZ_Digital_Methode_Obs_Zusammenpasst.pdf";
        break;
      case ROUTES.HANDOUT.OPEN_SPACE:
        fileName = "YCG_RZ_Digital_Methode_Open_Space.pdf";
        break;
      case ROUTES.HANDOUT.RAKETENSTART:
        fileName = "YCG_RZ_Digital_Methode_Raketenstart.pdf";
        break;
      case ROUTES.HANDOUT.SCHWEIGEPFLICHT:
        fileName = "YCG_RZ_Digital_Methode_Schweigepflicht.pdf";
        break;
      case ROUTES.HANDOUT.UNSERE_YOUCODE_WOCHE:
        fileName = "YCG_RZ_Digital_Methode_Unsere_YouCode_Woche.pdf";
        break;
      case ROUTES.HANDOUT.YOU_CAN_DO_IT:
        fileName = "YCG_RZ_Digital_Methode_You_Can_Do_It.pdf";
        break;
      case ROUTES.HANDOUT.WO_GIBT_ES_CODING:
        fileName = "YCG_Plakat_Haus_RZ2.pdf";
        break;
      case ROUTES.HANDOUT.AUDIO.IN_THE_SPACE:
        fileName = "In_The_Space.mp3";
        break;
      default:
        fileName = "";
    }

    let path = `${process.env.PUBLIC_URL}/content/handout/${fileName}`;

    if (fileName.length > 0) {
      fetch(path).then(response => {
        response.blob().then(blob => {
          const fileUrl = window.URL.createObjectURL(blob);
          const anchorTag = document.createElement('a');
          anchorTag.href = fileUrl;
          anchorTag.download = fileName;
          anchorTag.click();
        })
      });
    } else {
      return;
    }
  }, [pathName]);

  let prevScrollPosition = window.scrollY;
  window.onscroll = function () {
    let currentScrollPosition = window.scrollY;
    let documentHeight = document.body.offsetHeight;
    let windowHeight = window.innerHeight;
    setMiaPosition(currentScrollPosition >= documentHeight - windowHeight - MIA_POSITION);
    let navbar = document.getElementById("navbar");
    if (navbar) {
      if (currentScrollPosition < 200) {
        setHidden(false);
      } else {
        if (prevScrollPosition < currentScrollPosition) {
          //scrolled down
          setHidden(true);
        } else {
          // scrolled up
          setHidden(false);
        }
      }
    }
    prevScrollPosition = currentScrollPosition;
  }

  function determineHeaderStyle() {
    let classes = [styles.header];
    if (hidden) {
      classes.push(styles.hidden);
    }
    return classNames(classes);
  }

  useEffect(() => {
    if (loggedIn) {
      keycloak.loadUserInfo().then((profile: any) => {
        dispatch(userProfileSet(profile));
      });
    }
  }, [loggedIn, keycloak, dispatch])

  useEffect(() => {
    function setMedia() {
      dispatch(MediaWidthSet(window.innerWidth));
    }

    setMedia();
    window.addEventListener('resize', setMedia);
    return () => {
      window.removeEventListener('resize', setMedia);
    }
  }, [dispatch]);

  useEffect(() => {
    if (mediaWidth <= MEDIA_MOBILE_WIDTH) {
      dispatch(DeviceSet(DeviceNames.smartphone));
    } else if (mediaWidth > MEDIA_MOBILE_WIDTH && mediaWidth <= MEDIA_TABLET_WIDTH) {
      dispatch(DeviceSet(DeviceNames.tablet));
    } else {
      dispatch(DeviceSet(DeviceNames.computer));
    }
  }, [mediaWidth, dispatch])

  useEffect(() => {
    initialized && dispatch(userLoggedInSet(keycloak.authenticated));
  }, [dispatch, initialized, keycloak])

  useEffect(() => {
    dispatch(fetchLessons());
    if (loggedIn) {
      dispatch(fetchUserSettings());
    }
  }, [loggedIn, dispatch]);

  // Add CookieBot script
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('id', 'CookieBot');
    script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
    script.setAttribute('data-cbid', domainGroupId);
    script.setAttribute('data-blockingmode', 'auto');
    script.setAttribute('type', 'text/javascript');
    if (selectedLanguage === 'DEU') {
      script.setAttribute('data-culture', 'DE');
    } else if (selectedLanguage === 'ENG') {
      script.setAttribute('data-culture', 'EN');
    }
    const head = document.querySelector('html > head');
    head?.insertBefore(script, head.firstChild);

    return () => {
      head?.removeChild(script);
    };
  }, [selectedLanguage]);

  const selectThreeInterestsOrTopics = (userInterests: UserInterestsState) => {
    if (userInterests.areaOfInterests.length < 3) {
      return "Profile.selectThreeInterests";
    } else if (userInterests.topics.length < 3) {
      return "Profile.selectThreeTopics";
    } else {
      return "Profile.selectThreeFinished";
    }
  };

  const currentStep = useSelector(selectCurrentStep);

  function stepToOpen() {
    let step = <JobmatchStep1 step={1} next={ROUTES.DASHBOARD.JOBMATCH.STEP2}/>;
    switch (currentStep) {
      case 1: step = <JobmatchStep1 step={1}                                        next={ROUTES.DASHBOARD.JOBMATCH.STEP2}/>;  break;
      case 2: step = <JobmatchStep2 step={2} prev={ROUTES.DASHBOARD.JOBMATCH.STEP1} next={ROUTES.DASHBOARD.JOBMATCH.STEP3}/>;  break;
      case 3: step = <JobmatchStep3 step={3} prev={ROUTES.DASHBOARD.JOBMATCH.STEP2} next={ROUTES.DASHBOARD.JOBMATCH.STEP4}/>;  break;
      case 4: step = <JobmatchStep4 step={4} prev={ROUTES.DASHBOARD.JOBMATCH.STEP3} next={ROUTES.DASHBOARD.JOBMATCH.STEP5}/>;  break;
      case 5: step = <JobmatchStep5 step={5} prev={ROUTES.DASHBOARD.JOBMATCH.STEP4} next={ROUTES.DASHBOARD.JOBMATCH.STEP6}/>;  break;
      case 6: step = <JobmatchStep6 step={6} prev={ROUTES.DASHBOARD.JOBMATCH.STEP5} next={ROUTES.DASHBOARD.BASE}/>          ;  break;
    }
    return step;
  }

  return (
    <>
      {initialized &&
        <IntlProvider locale={selectedLanguage} messages={languageMessages()}>
          {wrapWithThemeProvider(
            <BrowserRouter basename={base}>
              <ScrollToTop/>
              <div className="App">
                <div className={styles.wrapper}>
                  <div className={determineHeaderStyle()} id={"navbar"}>
                    <Header isVisible={!hidden}/>
                  </div>
                  <div className={styles.content}>
                    <Routes>
                      <Route path={ROUTES.DASHBOARD.BASE} element={<Dashboard miaPosition={miaPosition}/>}/>
                      <Route path={ROUTES.DASHBOARD.INITIATIVE} element={<InitiativePage/>}/>
                      <Route path={ROUTES.DASHBOARD.BLOG.BASE} element={<Blog/>}/>
                      <Route path={ROUTES.DASHBOARD.BLOG.BLOG_VIEW} element={<BlogView/>}/>
                      <Route path={ROUTES.DASHBOARD.EVENTS} element={<EventsPage/>}/>
                      <Route path={ROUTES.DASHBOARD.MEDIA} element={<MediaPage/>}/>
                      <Route path={ROUTES.DASHBOARD.HANDOUT} element={<HandoutPage/>}/>
                      <Route path={ROUTES.DASHBOARD.JOBMATCH.INDEX} element={<JobmatchPage/>}>
                        <Route index element={stepToOpen()}/>
                        <Route path={ROUTES.DASHBOARD.JOBMATCH.STEP1} element={<JobmatchStep1 step={1}                                        next={ROUTES.DASHBOARD.JOBMATCH.STEP2}/>} />
                        <Route path={ROUTES.DASHBOARD.JOBMATCH.STEP2} element={<JobmatchStep2 step={2} prev={ROUTES.DASHBOARD.JOBMATCH.STEP1} next={ROUTES.DASHBOARD.JOBMATCH.STEP3}/>} />
                        <Route path={ROUTES.DASHBOARD.JOBMATCH.STEP3} element={<JobmatchStep3 step={3} prev={ROUTES.DASHBOARD.JOBMATCH.STEP2} next={ROUTES.DASHBOARD.JOBMATCH.STEP4}/>} />
                        <Route path={ROUTES.DASHBOARD.JOBMATCH.STEP4} element={<JobmatchStep4 step={4} prev={ROUTES.DASHBOARD.JOBMATCH.STEP3} next={ROUTES.DASHBOARD.JOBMATCH.STEP5}/>} />
                        <Route path={ROUTES.DASHBOARD.JOBMATCH.STEP5} element={<JobmatchStep5 step={5} prev={ROUTES.DASHBOARD.JOBMATCH.STEP4} next={ROUTES.DASHBOARD.JOBMATCH.STEP6}/>} />
                        <Route path={ROUTES.DASHBOARD.JOBMATCH.STEP6} element={<JobmatchStep6 step={6} prev={ROUTES.DASHBOARD.JOBMATCH.STEP5} next={ROUTES.DASHBOARD.BASE}/>          } />
                      </Route>
                      <Route path={ROUTES.DASHBOARD.NEWSLETTER} element={<NewsletterPage/>}/>
                      <Route path={ROUTES.CONTENT_VIEWER.CODING_CHALLENGE} element={<CodingChallenge miaPosition={miaPosition}/>}/>
                      <Route path={ROUTES.CONTENT_VIEWER.TYPEFORM_FEEDBACK} element={<TypeformFeedback miaPosition={miaPosition}/>}/>
                      <Route path={ROUTES.CONTENT_VIEWER.BASE} element={<CoursePage miaPosition={miaPosition}/>}/>
                      {loggedIn &&
                                <Route path={ROUTES.MIA_CUSTOMIZATION} element={<MiaCustomizationPage/>}/>
                            }
                      <Route path={ROUTES.LEGAL_NOTICE_PRIVACY_BAR.LEGAL_NOTICE} element={<LegalNoticePage/>}/>
                      <Route path={ROUTES.LEGAL_NOTICE_PRIVACY_BAR.PRIVACY_NOTICE} element={<PrivacyNoticePage/>}/>
                      <Route path={ROUTES.LEGAL_NOTICE_PRIVACY_BAR.CONTACT} element={<ContactPage/>}/>
                      <Route path={ROUTES.LEGAL_NOTICE_PRIVACY_BAR.CONTACT_SENT} element={<ContactPageSent/>}/>
                      <Route path={ROUTES.REGISTER.BASE} element={<RegistrationPage/>}/>
                      <Route path={ROUTES.REGISTER.PERSONALIZATION} element={<RegistrationPersonalizationPage selectThreeInterestsOrTopics={selectThreeInterestsOrTopics}/>}/>
                      <Route path={ROUTES.DASHBOARD.USERAVATAR.LEARNING_OFFERS} element={<LearningOffers/>}/>
                      <Route path={ROUTES.DASHBOARD.USERAVATAR.PROFILE} element={<ProfilePage selectThreeInterestsOrTopics={selectThreeInterestsOrTopics}/>}/>
                      <Route path={ROUTES.HANDOUT.ORDER} element={<OrderPage/>}/>
                      <Route path={ROUTES.HANDOUT.ORDER_SENT} element={<OrderPageSent/>}/>
                      <Route path={"*"} element={<Navigate to={ROUTES.DASHBOARD.BASE}/>}/>
                    </Routes>
                  </div>
                  <div className={styles.footer}>
                    <Footer/>
                  </div>
                  <div className={styles.LegalPrivacyNoticeBar}>
                    <LegalPrivacyNoticeBar/>
                  </div>
                </div>
              </div>
            </BrowserRouter>
          )}
        </IntlProvider>
      }
    </>
  );
}

export default App;
