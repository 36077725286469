import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Mia, MiaDirection} from "../Mia";
import {Chat, Sender} from "../MiaContent/Chat/Chat";
import styles from "./MiaHandler.module.scss";
import {selectMediaQueries} from "../../../redux/mediaQueriesSlice";
import {useIntl} from "react-intl";
import {miaApi, Status} from "../../../api/miaApi";
import {MiaResponse} from "../../../utils/types/api";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../utils/routes";
import {MiaSettings} from "../../../api/userApi";
import {MiaPose} from "../MiaCustomization/Poses/Poses";
import {useDispatch, useSelector} from "react-redux";
import {selectUserAvatar} from "../../../redux/userAvatarSlice";
import {selectUserLoggedIn, selectUserProfile} from "../../../redux/userLoggedInSlice";
import {
  miaCourseChatHistorySet,
  miaDashboardChatHistorySet,
  selectMiaChat,
  MiaChatHistory,
  ChatMessage
} from "../../../redux/miaChatSlice";

export interface MiaHandlerProps {
  onClose: () => void,
  onOpen?: () => void,
  open: boolean,
  miaDirection: MiaDirection,
  type?: MiaPose, // you can specify a set miaType, or let the dataloader set it by itself
  formatIdentifier?: string
  miaSettings?: MiaSettings;
  courseName?: string;
}

const EXTERNAL_LESSON = "externalLesson";
const EXTERNAL_WORKSHOP = "externalWorkshop";
/**
 * Component containing all logic pertaining to Mia and loading her content.
 */
export const MiaHandler: FunctionComponent<MiaHandlerProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 miaDirection,
                                                                 type,
                                                                 onOpen,
                                                                 formatIdentifier,
                                                                 miaSettings,
                                                                 courseName
                                                               }) => {

  const intl = useIntl();
  const navigate = useNavigate();
  const url = useLocation();
  const path = url.pathname;
  const dispatch = useDispatch();

  const [miaPose, setMiaPose] = useState<MiaPose>(type ? type : MiaPose.DEFAULT);
  const [pending, setPending] = useState(false);
  const [clearContentOnHide, setClearContentOnHide] = useState<boolean>(true);
  const [status, setStatus] = useState<Status | undefined>(undefined);
  const [userId, setUserId] = useState<string | null>("");

  let avatar = useSelector(selectUserAvatar);
  const {miaChatHistory} = useSelector(selectMiaChat);
  const loggedIn = useSelector(selectUserLoggedIn);
  const userProfile = useSelector(selectUserProfile);
  const {device} = useSelector(selectMediaQueries);

  useEffect(() => {
    if (loggedIn) {
      setUserId(userProfile.sub);
    } else {
      setUserId(localStorage.getItem("uuid"));
    }
  }, [loggedIn, userId, userProfile])

  const loadMiaContent = useCallback((content?: MiaResponse, chatMessage?: string, chatHistory?: MiaChatHistory) => {

      setMiaPose(content?.miaType ? content.miaType : MiaPose.DEFAULT);
      if (content?.clearOnHide) {
        setClearContentOnHide(content.clearOnHide);
      } else {
        setClearContentOnHide(false);
      }
      let newChats: Array<ChatMessage> = [];
      if (chatMessage) {
        newChats.push({
          sender: Sender.User,
          output: chatMessage
        });
      }
      if (content?.output) {
        newChats.push({
          sender: Sender.Mia,
          output: content?.output
        });
      }
      if (path === ROUTES.DASHBOARD.BASE) {
        if (chatHistory !== undefined && chatHistory?.miaDashboardChatHistory?.length > 0) {
          dispatch(miaDashboardChatHistorySet([...chatHistory.miaDashboardChatHistory, ...newChats]));
        } else {
          dispatch(miaDashboardChatHistorySet(newChats));
        }
      } else {
        if (chatHistory !== undefined && chatHistory?.miaCourseChatHistory.length > 0) {
          dispatch(miaCourseChatHistorySet([...chatHistory.miaCourseChatHistory,...newChats]));
        } else {
          dispatch(miaCourseChatHistorySet(newChats));
        }
      }
    }, [path, dispatch]
  );

  useEffect(() => {
    // determine MiaContent

    async function loadData() {
      if (status !== undefined) {
        let content: MiaResponse = {
          output: intl.formatMessage({id: "Mia.Introduction"}),
          miaType: MiaPose.BOOK,
          clearOnHide: false
        }

        if (path !== ROUTES.DASHBOARD.BASE || (path === ROUTES.DASHBOARD.BASE && localStorage.getItem('dashboard_first_visit') === 'false')) {
          content = {
            output: intl.formatMessage({id: "Mia.providingHelp"}),
            miaType: MiaPose.BOOK,
            clearOnHide: false
          }
        }

        //reset Mia context on every new instance after navigation to a new page
        await miaApi.closeMiaApi(userId);

        // process the loaded content based on what type of content needs to be displayed
        loadMiaContent(content);
        if (onOpen && !localStorage.getItem("show_mia")) {
          onOpen();
        }
      }
    }

    loadData().catch(error => console.error("Error while loading Mia content.", error));
  }, [loadMiaContent, intl, onOpen, status, path, userId]);

  useEffect(() => {
    //determine whether the user is logged-in, not logged-in, or if it's a newly registered user
    if (loggedIn && (new URLSearchParams(window.location.search)).get("newRegistration")) {
      setStatus(Status.newlyRegistered);
    } else if (loggedIn) {
      setStatus(Status.loggedIn);
    } else {
      setStatus(Status.notLoggedIn);
    }
  }, [loggedIn]);

  const switchToChat = useCallback(() => {
    if (miaChatHistory && miaChatHistory?.miaDashboardChatHistory?.length <= 0) {
      dispatch(miaDashboardChatHistorySet([{
        sender: Sender.Mia,
        output: intl.formatMessage({id: "Mia.switchToChat"})
      }]));
    }
  }, [intl, miaChatHistory, dispatch])

  useEffect(() => {
    if (!open) {
      switchToChat();
    }
  }, [open, switchToChat])

  async function closeMia() {
    if (clearContentOnHide) {
      // upon closing Mia, she should revert to the default "chat" functionality
      switchToChat();
    }
    await miaApi.closeMiaApi(userId);
    (path === ROUTES.DASHBOARD.BASE) && localStorage.setItem('dashboard_first_visit', 'false');

    if (formatIdentifier !== EXTERNAL_LESSON && formatIdentifier !== EXTERNAL_WORKSHOP && path !== ROUTES.DASHBOARD.BASE) {
      localStorage.setItem('course_first_visit', 'false')
    }
    onClose();
  }

  async function sendMessage(message: string) {
    let location = "";
    if (path === ROUTES.DASHBOARD.BASE) {
      location = "dashboard";
    } else if (path === ROUTES.CONTENT_VIEWER.BASE
      || path === ROUTES.CONTENT_VIEWER.CODING_CHALLENGE
      || path === ROUTES.CONTENT_VIEWER.TYPEFORM_FEEDBACK) {
      location = courseName ?? "";
    }
    loadMiaContent(undefined, message, miaChatHistory);
    try {
      setPending(true);
      const response = await miaApi.getMiaResponse(message, location, device, userId, loggedIn);
      loadMiaContent(response, message, miaChatHistory); // add the user's new message and Mia's response to the chat-log
    } finally {
      setPending(false);
    }
  }

  return (
    <div className={""}>
      <Mia type={miaPose} direction={miaDirection} onClose={closeMia} open={open} miaSettings={miaSettings}
           loggedIn={status === Status.loggedIn || status === Status.newlyRegistered} navigate={navigate} path={path}
      >
        <div className={styles.chatContainer}>
          <Chat
            chatHistory={miaChatHistory}
            onSend={sendMessage}
            userAvatar={avatar}
            miaSettings={miaSettings}
            path={path}
            pending={pending}
          />
        </div>
      </Mia>
    </div>
  );
};
