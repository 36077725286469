import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from './store';
import {TileContentsProps} from "@dis/colors-components";

const sliceName = 'jobmatch';

export interface JobmatchState {
  currentStep: number;
  allInterests: TileContentsProps[];
  chosenInterestsLabels: string[];
  allCompetencies: Competencies[];
  allJobs: Job[];
  filteredJobs: Job[];
  weightedResultingJobs: WeightedJob[];
  chosenJobId: string;
  chosenJob: WeightedJob;

}

export interface Job {
  id: string;
  title: string;
  competencies: Array<number>;
  interests: Array<string>;
  shortDescription: string;
  videoDescription: string;
  lesson: string;
  pdf: string;
  mentor: {photo: string, name:  string, profession:  string, qualification:  string, location:  string}
}

export interface WeightedJob extends Job {
  totalWeight: number;
}

interface Competencies {
  id: number;
  text: string;
}

const initialState: JobmatchState = {
  currentStep: 1,
  allInterests: [],
  chosenInterestsLabels: [],
  allCompetencies: [],
  allJobs: [],
  filteredJobs: [],
  weightedResultingJobs: [],
  chosenJobId: "",
  chosenJob: {id: "", title: "", competencies: [], interests: [], shortDescription: "", videoDescription: "", lesson: "",
    totalWeight: 0, pdf: "", mentor: {photo: "", name:  "", profession:  "", qualification:  "", location:  ""}
  }
}

const jobmatchSlice = createSlice({
  'name': sliceName,
  'initialState': initialState,
  'reducers': {
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setAllInterests(state: JobmatchState, action: PayloadAction<Array<TileContentsProps>>) {
      state.allInterests = action.payload;
    },
    setChosenInterestsLabels(state: JobmatchState, action: PayloadAction<Array<string>>) {
      state.chosenInterestsLabels = action.payload;
    },
    setAllCompetencies(state, action: PayloadAction<Array<Competencies>>) {
      state.allCompetencies = action.payload;
    },
    setAllJobs(state, action: PayloadAction<Array<Job>>) {
      state.allJobs = action.payload;
    },
    setFilteredJobs(state, action: PayloadAction<Array<Job>>) {
      state.filteredJobs = action.payload;
    },
    setWeightedResultingJobs(state, action: PayloadAction<Array<WeightedJob>>) {
      state.weightedResultingJobs = action.payload;
    },
    setChosenJobId(state, action) {
      state.chosenJobId = action.payload;
    },
    setChosenJob(state, action) {
      state.chosenJob = action.payload;
    },
    setImagesOfInterests: (state, action) => {
      const {id, imageData} = action.payload;
      const interest = state.allInterests.find((item) => item.id === id);
      if (interest) {
        interest.image = imageData;
      }
    },
    setPhotos: (state, action) => {
      const {id, photoData} = action.payload;
      const job = state.allJobs.find((item) => item.id === id);
      if (job) {
        job.mentor.photo = photoData;
      }
    }
  },
});

export const jobmatchReducer = jobmatchSlice.reducer;

export const {
  setCurrentStep,
  setAllInterests,
  setChosenInterestsLabels,
  setAllCompetencies,
  setAllJobs,
  setFilteredJobs,
  setWeightedResultingJobs,
  setChosenJobId,
  setChosenJob,
  setImagesOfInterests,
  setPhotos
} = jobmatchSlice.actions;

export const selectCurrentStep = (state: RootState) => state.jobmatch.currentStep;
export const selectAllInterests = (state: RootState) => state.jobmatch.allInterests;
export const selectChosenInterestsLabels = (state: RootState) => state.jobmatch.chosenInterestsLabels;
export const selectAllCompetencies = (state: RootState) => state.jobmatch.allCompetencies;
export const selectAllJobs = (state: RootState) => state.jobmatch.allJobs;
export const selectFilteredJobs = (state: RootState) => state.jobmatch.filteredJobs;
export const selectWeightedResultingJobs = (state: RootState) => state.jobmatch.weightedResultingJobs;
export const selectChosenJobId = (state: RootState) => state.jobmatch.chosenJobId;
export const selectChosenJob = (state: RootState) => state.jobmatch.chosenJob;
