import styles from './HairColorCustomizations.module.scss';
import {CustomizationOptionPreview, Customizations, CustomizationsStyles} from "./Customizations";


export enum HairColorCustomizationsIds {
    DEFAULT = "hair_0",
    TYPE_1 = "hair_1",
    TYPE_2 = "hair_2",
}

const HairColorCustomizationPreviews: CustomizationOptionPreview<HairColorCustomizationsIds>[] = [
    {id: HairColorCustomizationsIds.DEFAULT, previewColor1: "#aa733f", previewColor2: "#784616"},
    {id: HairColorCustomizationsIds.TYPE_1, previewColor1: "#db3a1e", previewColor2: "#b73320"},
    {id: HairColorCustomizationsIds.TYPE_2, previewColor1: "#1f2b50", previewColor2: "#23110a"}
]

const HairColorStyles: CustomizationsStyles<HairColorCustomizationsIds> = {
    [HairColorCustomizationsIds.DEFAULT]: styles.hair_0,
    [HairColorCustomizationsIds.TYPE_1]: styles.hair_1,
    [HairColorCustomizationsIds.TYPE_2]: styles.hair_2,
}

const HairColorCustomizations: Customizations<HairColorCustomizationsIds> = {
    previews: HairColorCustomizationPreviews,
    styles: HairColorStyles,
}

export default HairColorCustomizations;
