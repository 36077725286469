import React, {FunctionComponent} from 'react';
import styles from './CustomizationCategory.module.scss';
import {CustomizationOption, MiaCustomizationOption} from "../../components/CustomizationOption/CustomizationOption";


export interface CustomizationCategoryProps {
    name: string;
    options: MiaCustomizationOption[]
    selected: string,
    setSelected: (id: any) => void,
}


export const CustomizationCategory: FunctionComponent<CustomizationCategoryProps> = ({
                                                                                         name,
                                                                                         options,
                                                                                         setSelected,
                                                                                         selected,
                                                                                     }) => {

    return (
        <div className={styles.CustomizationCategory}>
            <div className={styles.categoryName}>{name}</div>
            <div className={styles.options}>
                {
                    options && options.length > 0 && options.map((option) => {
                        return (
                            <CustomizationOption
                                key={option.id}
                                selected={selected === option.id}
                                onClick={() => setSelected(option.id)}
                                color1={option.previewColor1}
                                color2={option.previewColor2}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
};
