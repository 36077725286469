import styles from './AccessoriesCustomizations.module.scss';
import {CustomizationOptionPreview, Customizations, CustomizationsStyles} from "./Customizations";


export enum AccessoriesCustomizationsIds {
    DEFAULT = "accessories_0",
    TYPE_1 = "accessories_1",
    TYPE_2 = "accessories_2",
}

const AccessoriesCustomizationPreviews: CustomizationOptionPreview<AccessoriesCustomizationsIds>[] = [
    {id: AccessoriesCustomizationsIds.DEFAULT, previewColor1: "#6c6f56", previewColor2: "#7a394f"},
    {id: AccessoriesCustomizationsIds.TYPE_1, previewColor1: "#6c6f56", previewColor2: "#bca11b"},
    {id: AccessoriesCustomizationsIds.TYPE_2, previewColor1: "#6c6f56", previewColor2: "#cc6815"}
]

const AccessoriesStyles: CustomizationsStyles<AccessoriesCustomizationsIds> = {
    [AccessoriesCustomizationsIds.DEFAULT]: styles.accessories_0,
    [AccessoriesCustomizationsIds.TYPE_1]: styles.accessories_1,
    [AccessoriesCustomizationsIds.TYPE_2]: styles.accessories_2,
}

const AccessoriesCustomizations: Customizations<AccessoriesCustomizationsIds> = {
    previews: AccessoriesCustomizationPreviews,
    styles: AccessoriesStyles,
}

export default AccessoriesCustomizations;
