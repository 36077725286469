import {CustomizationOptionPreview, Customizations, CustomizationsStyles} from "./Customizations";
import styles from "./SkinToneCustomizations.module.scss";


export enum SkinToneCustomizationsIds {
    DEFAULT = "skin_0",
    TYPE_1 = "skin_1",
    TYPE_2 = "skin_2",
}

const SkinToneCustomizationPreviews: CustomizationOptionPreview<SkinToneCustomizationsIds>[] = [
    {id: SkinToneCustomizationsIds.DEFAULT, previewColor1: "#f7cf7a", previewColor2: "#f9b776"},
    {id: SkinToneCustomizationsIds.TYPE_1, previewColor1: "#c98349", previewColor2: "#ba782f"},
    {id: SkinToneCustomizationsIds.TYPE_2, previewColor1: "#724729", previewColor2: "#703f1b"}
]

const SkinToneStyles: CustomizationsStyles<SkinToneCustomizationsIds> = {
    [SkinToneCustomizationsIds.DEFAULT]: styles.skin_0,
    [SkinToneCustomizationsIds.TYPE_1]: styles.skin_1,
    [SkinToneCustomizationsIds.TYPE_2]: styles.skin_2,
}

const SkinToneCustomizations: Customizations<SkinToneCustomizationsIds> = {
    previews: SkinToneCustomizationPreviews,
    styles: SkinToneStyles,
}

export default SkinToneCustomizations;
