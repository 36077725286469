import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './MiaCustomizationPage.module.scss';
import {CustomizationCategory} from "../../building-blocks/CustomizationCategory/CustomizationCategory";
import {Button} from "@dis/colors-components";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {saveUserSettings, selectUserSettings} from "../../redux/userSettingsSlice";
import {userApi} from "../../api/userApi";
import jsonpatch from "fast-json-patch";
import SkinToneCustomizations, {
    SkinToneCustomizationsIds
} from "../../components/Mia/MiaCustomization/Customizations/SkinToneCustomizations";
import HairColorCustomizations, {
    HairColorCustomizationsIds
} from "../../components/Mia/MiaCustomization/Customizations/HairColorCustomizations";
import EyesColorCustomizations, {
    EyesColorCustomizationsIds
} from "../../components/Mia/MiaCustomization/Customizations/EyesColorCustomizations";
import JacketCustomizations, {
    JacketCustomizationsIds
} from "../../components/Mia/MiaCustomization/Customizations/JacketCustomizations";
import {MiaCustomization} from "../../components/Mia/MiaCustomization/MiaCustomization";
import {MiaPose} from "../../components/Mia/MiaCustomization/Poses/Poses";
import {useIntl} from "react-intl";
import AccessoriesCustomizations, {
    AccessoriesCustomizationsIds
} from "../../components/Mia/MiaCustomization/Customizations/AccessoriesCustomizations";
import PantsCustomizations, {
    PantsCustomizationsIds
} from "../../components/Mia/MiaCustomization/Customizations/PantsCustomizations";
import {selectMiaCustomizationUrl} from "../../redux/miaCustomizationUrlSlice";
import {ROUTES} from "../../utils/routes";


const MiaCustomizationPage: FunctionComponent = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const intl = useIntl();

    const userSettings = useSelector(selectUserSettings);
    const miaCustomizationUrl = useSelector(selectMiaCustomizationUrl)

    const [skinTone, setSkinTone] = useState<SkinToneCustomizationsIds>(SkinToneCustomizationsIds.DEFAULT);
    const [hairColor, setHairColor] = useState<HairColorCustomizationsIds>(HairColorCustomizationsIds.DEFAULT);
    const [eyeColor, setEyeColor] = useState<EyesColorCustomizationsIds>(EyesColorCustomizationsIds.DEFAULT);
    const [jacketOption, setJacketOption] = useState<JacketCustomizationsIds>(JacketCustomizationsIds.DEFAULT);
    const [pantsOption, setPantsOption] = useState<PantsCustomizationsIds>(PantsCustomizationsIds.DEFAULT);
    const [accessoriesOption, setAccessoriesOption] = useState<AccessoriesCustomizationsIds>(AccessoriesCustomizationsIds.DEFAULT);

    useEffect(() => {
        // load saved Data
        if (userSettings?.miaSettings?.customSkin)
            setSkinTone(userSettings.miaSettings.customSkin)
        if (userSettings?.miaSettings?.customHair)
            setHairColor(userSettings.miaSettings.customHair)
        if (userSettings?.miaSettings?.customEyes)
            setEyeColor(userSettings.miaSettings.customEyes)
        if (userSettings?.miaSettings?.customJacket)
            setJacketOption(userSettings.miaSettings.customJacket)
        if (userSettings?.miaSettings?.customPants)
            setPantsOption(userSettings.miaSettings.customPants)
        if (userSettings?.miaSettings?.customAccessories)
            setAccessoriesOption(userSettings.miaSettings.customAccessories)
    }, [userSettings])

    const saveChanges = async () => {
        const updatedSettings = jsonpatch.deepClone(userSettings);
        if (!updatedSettings.miaSettings) {
            updatedSettings.miaSettings = {};
        }
        updatedSettings.miaSettings.customSkin = skinTone;
        updatedSettings.miaSettings.customHair = hairColor;
        updatedSettings.miaSettings.customEyes = eyeColor;
        updatedSettings.miaSettings.customJacket = jacketOption;
        updatedSettings.miaSettings.customPants = pantsOption;
        updatedSettings.miaSettings.customAccessories = accessoriesOption;

        const updates = await userApi.patchUserSettings(userSettings, updatedSettings);
        if (updates) {
            dispatch(saveUserSettings(updates));
            navigate(-1);
        }
    }

    function buttonLabel() {
        if (miaCustomizationUrl === ROUTES.CONTENT_VIEWER.BASE) {
            return intl.formatMessage({id: "MiaCustomizationPage.saveContent"})
        } else {
            return intl.formatMessage({id: "MiaCustomizationPage.save"})
        }
    }

    return (
        <div className={styles.MiaCustomizationPage}>
            <div className={styles.header}>{intl.formatMessage({id: "MiaCustomizationPage.header"})}</div>
            <div className={styles.description}>{intl.formatMessage({id: "MiaCustomizationPage.description"})}</div>
            <div className={styles.customization}>
                <div className={styles.selection}>
                    <div className={styles.categories}>
                        <CustomizationCategory name={intl.formatMessage({id: "MiaCustomizationPage.hairColor"})}
                                               options={HairColorCustomizations.previews}
                                               selected={hairColor}
                                               setSelected={setHairColor}/>
                        <CustomizationCategory name={intl.formatMessage({id: "MiaCustomizationPage.skinTone"})}
                                               options={SkinToneCustomizations.previews}
                                               selected={skinTone}
                                               setSelected={setSkinTone}/>
                        <CustomizationCategory name={intl.formatMessage({id: "MiaCustomizationPage.eyeColor"})}
                                               options={EyesColorCustomizations.previews}
                                               selected={eyeColor}
                                               setSelected={setEyeColor}/>
                    </div>
                    <div className={styles.mia}>
                        <MiaCustomization pose={MiaPose.POCKET}
                                          skinTone={skinTone}
                                          hairColor={hairColor}
                                          eyesColor={eyeColor}
                                          jacketColor={jacketOption}
                                          pantsColor={pantsOption}
                                          accessoriesColor={accessoriesOption}/>

                    </div>
                    <div className={styles.categories}>
                        <CustomizationCategory name={intl.formatMessage({id: "MiaCustomizationPage.jacket"})}
                                               options={JacketCustomizations.previews}
                                               selected={jacketOption}
                                               setSelected={setJacketOption}/>
                        <CustomizationCategory name={intl.formatMessage({id: "MiaCustomizationPage.pants"})}
                                               options={PantsCustomizations.previews}
                                               selected={pantsOption}
                                               setSelected={setPantsOption}/>
                        <CustomizationCategory name={intl.formatMessage({id: "MiaCustomizationPage.accessories"})}
                                               options={AccessoriesCustomizations.previews}
                                               selected={accessoriesOption}
                                               setSelected={setAccessoriesOption}/>
                    </div>
                </div>
                <div className={styles.button}>
                    <Button label={buttonLabel()} onClick={saveChanges}/>
                </div>
            </div>
        </div>
    );
}

export default MiaCustomizationPage;
