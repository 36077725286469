import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

import {RootState} from "./store";
import {fetchLessons} from "./fetchActions";
import {FormatConfigurationWithMetaData, LessonWithImage, ShallowEntity} from "../utils/types/api";
import {phasesAdapter} from "./phaseSlice";
import {learningElementAdapter} from "./learningElementsSlice";
import {formatConfigurationAdapter} from "./formatConfigurationsSlice";
import {tutorAdapter} from "./tutorSlice";
import { MEDIA_MOBILE_WIDTH, MEDIA_TABLET_WIDTH } from "../utils/helper";
import { selectMediaQueries } from "./mediaQueriesSlice";

/*
 * This slice acts as a replacement for a method/endpoint providing a set of available Lesson Templates. These Lesson
 * Templates are displayed on the Dashboard to allow the user to inspect it (out of curiosity) or to use it to create
 * a new Lesson out of it.
 * To fake these Lesson Templates we fetch all Lessons of the given time frame and only pick those with a valid `id`, so
 * we exclude unplanned Lessons.
 *
 * Thus, in future,
 *   - this slice might be renamed in the global Redux state
 *   - the called API might be provided a parameter to respond only with Lesson Templates
 *   - the parameters `from` and `to` might disappear
 */

const sliceName = "lessons";

const additionalOriginalCourses:Array<string> = ['Was sind eigentlich Cookies?', 'Coding in deiner Stadt'];

const externalWorkshop = "externalWorkshop";

const timeCompareFunctionNewestFirst = (a: ShallowEntity<LessonWithImage>, b: ShallowEntity<LessonWithImage>) => {
    if (!a.createdAt) {
        return 1;
    } else if (!b.createdAt) {
        return -1;
    }

    if (a.createdAt > b.createdAt) {
        return -1;
    } else if (a.createdAt < b.createdAt){
        return 1;
    } else {
        return 0;
    }
}

const dateCompareFunction = (lessons:ShallowEntity<LessonWithImage>[]) => (state: RootState) => {
    return lessons.sort((a,b) => {
        if(a.id && b.id) {
            const formatConfigA = selectFormatConfigurationFromLesson(a.id)(state);
            const formatConfigB = selectFormatConfigurationFromLesson(b.id)(state);

            if(formatConfigA && formatConfigB) {
                if(formatConfigA.configuration?.lesson_date > formatConfigB.configuration?.lesson_date) {
                    return  -1;
                } else if (formatConfigA.configuration?.lesson_date < formatConfigB.configuration?.lesson_date) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }
        return 1;
    })
}

/**
 * The Entity Adapter managing {@link Lesson} entities.
 *
 * Entities are sorted by their `name`.
 */
export const lessonsAdapter = createEntityAdapter<ShallowEntity<LessonWithImage>>({
    "sortComparer": (a, b) => (a.name ?? "")?.localeCompare(b.name ?? "")
});

/**
 * The Redux slice handling actions on {@link Lesson} entities.
 */
const lessonsSlice = createSlice({
    "name": sliceName,
    "initialState": lessonsAdapter.getInitialState(),
    "reducers": {},
    "extraReducers": (builder) => builder
        // Add all Lesson entities fetched by fetchLessons if the request succeeded.
        .addCase(fetchLessons.fulfilled, (state, action) => {
            if (action.payload.entities.Lesson) {
                lessonsAdapter.upsertMany(state, action.payload.entities.Lesson);
            }
        })
});

/**
 * To be used by the Redux storage to set up the {@link Lesson} slice.
 */
export const lessonsReducer = lessonsSlice.reducer;

/*
 * A selector dictionary giving access to
 *
 * - `selectAllLessons`: all stored Lesson entities
 */
export const {
    "selectAll": selectAllLessons,
    "selectById": selectLesson
} = lessonsAdapter.getSelectors<RootState>((state) => state.lessons);

export const selectAllLessonsMediaSpecific = () => (state: RootState) => {
    const lessons = lessonsAdapter.getSelectors().selectAll(state.lessons);
    const { mediaWidth } = selectMediaQueries(state);
    return lessons.filter(lesson => {
        if(lesson.id) {
            const formatConfiguration = selectFormatConfigurationFromLesson(lesson.id)(state);
            if(formatConfiguration) {
                if(formatConfiguration.configuration.tablet && !formatConfiguration.configuration.smartphone) {
                    return mediaWidth > MEDIA_MOBILE_WIDTH;
                } else if (!formatConfiguration.configuration.tablet && !formatConfiguration.configuration.smartphone) {
                    return mediaWidth > MEDIA_TABLET_WIDTH;
                } else if (formatConfiguration.configuration.smartphone && !formatConfiguration.configuration.tablet) {
                    return mediaWidth <= MEDIA_MOBILE_WIDTH || mediaWidth > MEDIA_TABLET_WIDTH;
                }
            }
        }
        return true;
    })
}

export const selectAllLessonsForRecommendation = () => (state: RootState) => {
    return selectAllLessonsMediaSpecific()(state);
}

export const selectLessonBySubjectId = (subjectId: string) => (state: RootState) => {
    const lessons = selectAllLessonsMediaSpecific()(state);
    return lessons.filter(lesson => {
        return lesson.subject === subjectId;
    });
}

export const selectLessonByThemes = (subjectName: string) => (state: RootState) => {
    const lessons = selectAllLessonsMediaSpecific()(state);
    return lessons.filter(lesson => {
        if (lesson.id) {
            const formatConfiguration = selectFormatConfigurationFromLesson(lesson.id)(state);
            return formatConfiguration?.formatIdentifier !== externalWorkshop && (formatConfiguration?.configuration.theme_1 === subjectName || formatConfiguration?.configuration.theme_2 === subjectName);
        } else {
            return false;
        }
    });
}

export const selectLessonByName = (lessonName: string | undefined) => (state: RootState) => {
    const lessons = selectAllLessonsMediaSpecific()(state);
    return lessons.filter(lesson => {
       return lesson.name === lessonName;
    });
}

export const selectLessonsByName = (names: Array<string> | undefined) => (state: RootState) => {
    const filteredLessons:Array<ShallowEntity<LessonWithImage>> = [];

    const lessons = selectAllLessonsMediaSpecific()(state);

    names?.filter(name => lessons.forEach(lesson => {
        if(lesson.id && lesson.name) {
            name.includes(lesson.name) && filteredLessons.push(lesson);
        }
    }))

    return filteredLessons.reverse();
}

function checkAgeOnFormatConfiguration(age: string, formatConfiguration: ShallowEntity<FormatConfigurationWithMetaData> | undefined) {
    switch (age) {
        case "1":
            return formatConfiguration?.configuration.age_group_1;
        case "2":
            return formatConfiguration?.configuration.age_group_2;
        case "3":
            return formatConfiguration?.configuration.age_group_3;
        case "4":
            return formatConfiguration?.configuration.age_group_4;
        default:
            return true;
    }
}

export const searchLessons = (searchKeyword: string, subjectName: string, knowledge: number | undefined, age: string) => (state: RootState) => {

    const lessons = selectAllLessonsMediaSpecific()(state);
    const searchToLowerCase = searchKeyword.toLowerCase();

    return lessons.filter(lesson => {
        if (lesson.id) {
            const formatConfiguration = selectFormatConfigurationFromLesson(lesson.id)(state);

            const isMatchingAge = checkAgeOnFormatConfiguration(age, formatConfiguration);

            const isMatchingKnowledge = !knowledge || formatConfiguration?.configuration.previous_knowledge === knowledge;

            let isMatchingSubject = true;

            if(subjectName.length > 0) {
                isMatchingSubject = formatConfiguration?.configuration.theme_1.toLowerCase() === subjectName.toLowerCase() ||
                  formatConfiguration?.configuration.theme_2.toLowerCase() === subjectName.toLowerCase();
            }

            const isMatchingSearchText = lesson.name.toLowerCase().includes(searchToLowerCase) ||
              lesson.description?.toLowerCase().includes(searchToLowerCase) ||
              lesson.subject?.toLowerCase().includes(searchToLowerCase) ||
              formatConfiguration?.configuration.theme_1.toLowerCase().includes(searchToLowerCase) ||
              formatConfiguration?.configuration.theme_2.toLowerCase().includes(searchToLowerCase);

            return  isMatchingSearchText && isMatchingSubject && isMatchingKnowledge && isMatchingAge;

        } else {
            return false;
        }
    });
}

export const selectNewestLessons = (amount: number) => (state: RootState) => {
    const lessons = selectAllLessonsMediaSpecific()(state);
    const sortedLessons = dateCompareFunction(lessons)(state);
    const lessonsWithoutExternalWorkshops = sortedLessons.filter(lesson => {
        if(lesson.id) {
            const formatConfiguration = selectFormatConfigurationFromLesson(lesson.id)(state);
            if(formatConfiguration) {
                return formatConfiguration.formatIdentifier !== externalWorkshop;
            }
        }
        return true;
    })

    return lessonsWithoutExternalWorkshops.slice(0, amount);
}

export const selectOriginalLessons = () => (state: RootState) => {
    const lessons = selectAllLessonsMediaSpecific()(state).sort(timeCompareFunctionNewestFirst);
    const originals = lessons.filter(lesson => {
        if (lesson.id) {
            const formatConfiguration = selectFormatConfigurationFromLesson(lesson.id)(state);
            if (formatConfiguration) {
                return formatConfiguration.formatIdentifier !== "externalLesson" && formatConfiguration.formatIdentifier !== externalWorkshop;
            }
        }
        return true;
    });
    const externalLessons = selectLessonsByName(additionalOriginalCourses)(state);

    return [...originals, ...externalLessons];
}

//TODO: Remove if lesson has meta data directly instead of accessing format configuration
export const selectFormatConfigurationFromLesson = (lessonId: string) => (state: RootState) => {
    const lesson = lessonsAdapter.getSelectors().selectById(state.lessons, lessonId);
    const phaseId = lesson?.phases && lesson?.phases[0];

    if (phaseId !== undefined) {
        const phase = phasesAdapter.getSelectors().selectById(state.phases, phaseId);
        const learningElementId = phase?.learningElements && phase.learningElements[0];

        if (learningElementId !== undefined) {
            const learningElement = learningElementAdapter.getSelectors().selectById(state.learningElements, learningElementId);
            const formatConfigurationId = learningElement?.formatConfiguration

            if (formatConfigurationId !== undefined) {
                return formatConfigurationAdapter.getSelectors().selectById(state.formatConfigurations, formatConfigurationId);
            }
        }
    }
}

export const selectLearningElementsFromLesson = (lessonId: string) => (state: RootState) => {
    const lesson = lessonsAdapter.getSelectors().selectById(state.lessons, lessonId);
    const phaseId = lesson?.phases && lesson?.phases[0];

    if (phaseId !== undefined) {
        const phase = phasesAdapter.getSelectors().selectById(state.phases, phaseId);
        const learningElementId = phase?.learningElements && phase.learningElements[0];

        if (learningElementId !== undefined) {
            return learningElementAdapter.getSelectors().selectById(state.learningElements, learningElementId);
        }
    }
}

export const selectTutorFromLesson = (lessonId: string) => (state: RootState) => {
    const lesson = lessonsAdapter.getSelectors().selectById(state.lessons, lessonId);
    const tutorId = lesson?.tutor;

    if (tutorId !== undefined) {
        return tutorAdapter.getSelectors().selectById(state.tutor, tutorId);
    }
}

export const selectExternalWorkshop = () => (state: RootState) => {
    const lessons = selectAllLessonsMediaSpecific()(state).sort(timeCompareFunctionNewestFirst);
    return lessons.filter(lesson => {
        if(lesson.id) {
            const formatConfiguration = selectFormatConfigurationFromLesson(lesson.id)(state);
            return formatConfiguration?.formatIdentifier === externalWorkshop;
        } else {
            return false;
        }
    });
}
