import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./Dashboard.module.scss";
import { useIntl } from "react-intl";
import { loadJsonFile, MEDIA_TABLET_WIDTH, MEDIA_MOBILE_WIDTH, workshop } from "../../utils/helper";
import { useKeycloak } from "@react-keycloak/web";
import { ROUTES } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  searchLessons,
  selectAllLessonsForRecommendation,
  selectExternalWorkshop, selectLessonsByName,
  selectNewestLessons,
  selectOriginalLessons
} from "../../redux/lessonsSlice";
import { ContentTileWrapper } from "../../components/ContentTile/ContentTileWrapper";
import { MiaHandler } from "../../components/Mia/MiaHandler/MiaHandler";
import { MiaDirection } from "../../components/Mia/Mia";
import { MiaButton } from "../../components/Mia/MiaButton";
import { selectAllSubjectsMediaSpecific } from "../../redux/subjectSlice";
import { ContentTileSubjectGroup } from "../../components/ContentTile/ContentTileSubjectGroup";
import { selectMiaSettings, selectUserSettings } from "../../redux/userSettingsSlice";
import { selectLanguage } from "../../redux/languageSlice";
import {
  DashboardFiltersFilteredSubjectIdSet,
  DashboardFiltersInputAgeSet,
  DashboardFiltersInputKnowledgeSet,
  DashboardFiltersSearchInputSet,
  InputDropdownProps,
  InputKnowledgeProps,
  selectDashboardFilters
} from "../../redux/dashboardFiltersSlice";
import { counterApi } from "../../api/counterApi";
import { Carousel, Dropdown, OptionProps } from "@dis/colors-components";
import { DashboardBannerCarousel } from "../../building-blocks/DashboardBannerCarousel/DashboardBannerCarousel";
import { selectRecommendations } from "../../redux/recommendationSlice";
import { USER_RECOMMENDATION } from "../../api/recommenderApi";
import { internalOriginalLessonsSet, selectInternalOriginalContent } from "../../redux/internalOriginalContentSlice";
import { selectMediaQueries } from "../../redux/mediaQueriesSlice";
import { LessonWithImage, ShallowEntity } from "../../utils/types/api";
import {DebouncedTextInput} from "../../components/DebouncedTextInput/DebouncedTextInput";
import { selectUserLoggedIn } from "../../redux/userLoggedInSlice";
import { miaCourseChatHistorySet, miaDashboardChatHistorySet } from "../../redux/miaChatSlice";
import {StartpageBannerCarousel} from "../../building-blocks/StartpageBannerCarousel/StartpageBannerCarousel";

interface DashboardProps {
  miaPosition?: boolean;
}

const NEWEST_LESSONS_AMOUNT = 14;
const SCROLL_HEIGHT = 95;
const DEFAULT_SCROLL_SPEED = 0.75;
const MOBILE_SCROLL_SPEED = 1;
const DEBOUNCE_TIMEOUT = 200;
//days in milliseconds, two days
const MIA_CHAT_HISTORY_DELETE = 172800000;

function isInSearchMode(searchInput: string, filteredSubjectName: string, inputKnowledge: number | undefined, inputAge: string) {
  return searchInput.length !== 0 || filteredSubjectName.length !== 0 || inputKnowledge !== undefined || inputAge.length !== 0;
}

const Dashboard: FunctionComponent<DashboardProps> = ({miaPosition}) => {

  const intl = useIntl();
  const navigate = useNavigate();
  const location = window.location.href.split("#")[0];
  const dispatch = useDispatch();

  const { initialized } = useKeycloak();

  const { mediaWidth } = useSelector(selectMediaQueries);
  const loggedIn = useSelector(selectUserLoggedIn);
  const allLessonsForRecommendation = useSelector(selectAllLessonsForRecommendation());
  const newestLessons = useSelector(selectNewestLessons(NEWEST_LESSONS_AMOUNT));
  const originalLessons = useSelector(selectOriginalLessons());
  const subjects = useSelector(selectAllSubjectsMediaSpecific());
  const externalWorkshops = useSelector(selectExternalWorkshop());

  const recommendations = useSelector(selectRecommendations);

  const miaSettings = useSelector(selectMiaSettings);

  const internalOriginalContent = useSelector(selectInternalOriginalContent);

  const userSettings = useSelector(selectUserSettings);

  const startedCourses: ShallowEntity<LessonWithImage>[] = useSelector(selectLessonsByName(userSettings.courseSettings?.startedCourses));

  const dashboardFilters = useSelector(selectDashboardFilters);
  const searchInput = dashboardFilters.searchInput;
  const inputAge = dashboardFilters.inputAge;
  const inputKnowledge = dashboardFilters.inputKnowledge;
  const filteredSubject = dashboardFilters.filteredSubjectId;

  const subjectOptions: Array<OptionProps> = useMemo(() => {
    return subjects.map((subject) => {
      return {
        value: subject.id || "",
        label: subject.name,
        onClick: () => {
          dispatch(DashboardFiltersFilteredSubjectIdSet({
            value: subject.id || "",
            label: subject.name
          }));
        }
      };
    });
  }, [subjects, dispatch]);

  const [showMia, setShowMia] = useState(false);

  const searchResult = useSelector(searchLessons(searchInput, filteredSubject.label, inputKnowledge.value, inputAge.value));

  const selectedLanguage = useSelector(selectLanguage);

  const [currentToolTip, setCurrentToolTip] = useState("");

  const [scrollSpeed, setScrollSpeed] = useState<number>(DEFAULT_SCROLL_SPEED);

  useEffect(() => {
    async function loadData() {
      let data;
      switch (selectedLanguage) {
        case "DEU":
          data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/content-tile-wrapper/page-content.json`);
          break;
        case "ENG":
          data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/content-tile-wrapper/page-content-eng.json`);
          break;
        default:
          data = await loadJsonFile(`${process.env.PUBLIC_URL}/content/content-tile-wrapper/page-content.json`);
          break;
      }
        dispatch(internalOriginalLessonsSet(await data.json()));
    }

    loadData().catch(console.error);
  }, [selectedLanguage, dispatch]);

  useEffect(() => {
    //set scroll speed
    if(mediaWidth <= MEDIA_MOBILE_WIDTH) setScrollSpeed(MOBILE_SCROLL_SPEED);
    else setScrollSpeed(DEFAULT_SCROLL_SPEED);
  },[scrollSpeed, mediaWidth])

  useEffect(() => {
    const lessonId = sessionStorage.getItem("lesson_id");
    if (lessonId) {
      window.location.href = `#${lessonId}`;
      const currentHeight = window.scrollY;
      window.scrollTo(0, currentHeight - SCROLL_HEIGHT);
      sessionStorage.removeItem("lesson_id");
    }

  }, []);

  useEffect(() => {
    // count unique visitors
    if (localStorage.getItem("hasVisited") !== "1") {
      counterApi.incrementCounter("visitors").then(
        () => localStorage.setItem("hasVisited", "1")
      );

    }
    // count unique visitors per day
    let today = new Date().getTime();
    const lastVisit = Number(localStorage.getItem("lastVisit"));
    if (today !== lastVisit) {
      counterApi.incrementCounter("dailyVisitors").then(
        () => localStorage.setItem("lastVisit", `${today}`)
      );
    }

    const miaChatHistoryDelete = Number(localStorage.getItem("mia_chat_history_delete"));
    if(miaChatHistoryDelete) {
      if(today >= (miaChatHistoryDelete + MIA_CHAT_HISTORY_DELETE)) {
        localStorage.setItem("dashboard_first_visit", 'true');
        localStorage.setItem("course_first_visit", 'true');
        localStorage.setItem("mia_chat_history_delete", `${today}`);
      }
    } else {
      localStorage.setItem("mia_chat_history_delete", `${today}`);
    }


    return () => {
      localStorage.setItem("dashboard_first_visit", "false");
      localStorage.setItem("show_mia", "false");
      setShowMia(false);
    }
  }, []);

  useEffect(() => {
    if(localStorage.getItem("dashboard_first_visit") === 'true' && localStorage.getItem("course_first_visit") === "true") {
      dispatch(miaDashboardChatHistorySet([]));
      dispatch(miaCourseChatHistorySet([]));
    }
  },[dispatch])

  const closeMia = () => {
    localStorage.setItem("show_mia", "false");
    setShowMia(false);
  };

  const openMia = useCallback(() => {
    if(!localStorage.getItem('dashboard_first_visit')) {
      localStorage.setItem('dashboard_first_visit', 'true');
    }
    localStorage.setItem("show_mia", "true");
    setShowMia(true);
  }, []);

  const defaultValue = (object: InputDropdownProps) =>
    (object.label.length === 0) ? undefined :
      [{ value: object.value, label: object.label }];

  const knowledgeDefaultValue = (knowledge: InputKnowledgeProps) =>
    (knowledge.label.length === 0) ? undefined :
      [{ value: JSON.stringify(knowledge.value), label: knowledge.label }];

  const onClick = (event: React.MouseEvent, id?: string, name?: string) => {
    if (event.ctrlKey && name) {
      sessionStorage.setItem("lesson_id", name);
    } else if (id && name && !event.ctrlKey) {
      sessionStorage.setItem("lesson_id", name);
      navigate(`${ROUTES.CONTENT_VIEWER.BASE}?content-id=${id}`);
    }
  };

  const knowledgeInputOptions = [
    { value: "1", label: intl.formatMessage({ id: "Label.Easy" }), toolTipText: intl.formatMessage({id: "Tooltip.Easy"}) },
    { value: "2", label: intl.formatMessage({ id: "Label.Medium" }), toolTipText: intl.formatMessage({id: "Tooltip.Medium"}) },
    { value: "3", label: intl.formatMessage({ id: "Label.Hard" }), toolTipText: intl.formatMessage({id: "Tooltip.Hard"}) }
  ]

  const onMouseOver = (e: React.MouseEvent) => {

    const element = e?.target as HTMLElement;
    knowledgeInputOptions.forEach((option) => {
      if(option.label === element.innerText) {
        setCurrentToolTip(option.toolTipText);
      }
    });
  };

  function fontSize () {
    if(mediaWidth <= MEDIA_MOBILE_WIDTH) {
      return "24px";
    } else if(mediaWidth > MEDIA_MOBILE_WIDTH && mediaWidth <= MEDIA_TABLET_WIDTH) {
      return "28px";
    } else {
      return ""
    }
  }

  const headerStyle = {
    fontSize: fontSize()
  }

  function miaStyle() {
    if(mediaWidth <= MEDIA_TABLET_WIDTH && showMia) {
      return {
        zIndex: 12
      }
    }
    return {
      bottom: miaPosition && mediaWidth <= MEDIA_TABLET_WIDTH ? "110px" : ""
    }
  }

  return (
    <div className={styles.Dashboard}>
      <div className={styles.relative}>
        <div className={styles.banner}>
          {initialized && (
              (loggedIn && <DashboardBannerCarousel />) || <StartpageBannerCarousel/>)
          }
          {initialized &&
            <div style={miaStyle()} className={styles.mia}>
              <div className={styles.miaButton}>
                <MiaButton openMia={openMia} visible={!showMia} miaSettings={miaSettings}
                           direction={MiaDirection.LEFT} />
              </div>
              <div className={styles.miaHandler}>
                <MiaHandler onClose={closeMia} open={showMia} onOpen={openMia}
                            miaDirection={MiaDirection.LEFT}
                            miaSettings={miaSettings}
                />
              </div>
            </div>
          }
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.searchBar}>
          <div className={styles.firstSearchBarEntry}>
            <DebouncedTextInput
              debounceTimeout={DEBOUNCE_TIMEOUT}
              onChange={e => {
                dispatch(DashboardFiltersSearchInputSet(e.target.value));
              }}
              placeholder={intl.formatMessage({ id: "Dashboard.SearchFieldPlaceholder" })}
              value={searchInput}
              clear={() => dispatch(DashboardFiltersSearchInputSet(""))}
            />
          </div>
          <div className={styles.topicOptions}>
            <Dropdown
              placeholder={intl.formatMessage({ id: "Dashboard.Subject" })}
              options={subjectOptions}
              selectedOptions={defaultValue(filteredSubject)}
              onChange={(subject) => {
                dispatch(DashboardFiltersFilteredSubjectIdSet({
                  value: subject ? subject.value : "",
                  label: subject ? subject.label : ""
                }));
              }}
              isClearable
              searchable={false}
              whiteBackground={false}
              style={{minWidth:"204px"}}
            />
          </div>
          <div className={styles.ageOptions}>
            <Dropdown
              placeholder={intl.formatMessage({ id: "Dashboard.AgeGroup" })}
              options={[
                { value: "1", label: "6–9 " + intl.formatMessage({ id: "Label.Years" }) },
                { value: "2", label: "10–12 " + intl.formatMessage({ id: "Label.Years" }) },
                { value: "3", label: "13–15 " + intl.formatMessage({ id: "Label.Years" }) },
                { value: "4", label: "16 " + intl.formatMessage({ id: "Label.YearsAndOlder" }) }
              ]}
              selectedOptions={defaultValue(inputAge)}
              onChange={(age) => {
                dispatch(DashboardFiltersInputAgeSet({
                  value: age ? age.value : "",
                  label: age ? age.label : ""
                }));
              }}
              isClearable
              searchable={false}
              whiteBackground={false}
              style={{minWidth:"204px"}}
            />
          </div>
          <div className={styles.preKnowledgeOptions}>
            <Dropdown
              placeholder={intl.formatMessage({ id: "Course.PriorKnowledge" })}
              selectedOptions={knowledgeDefaultValue(inputKnowledge)}
              options={knowledgeInputOptions}
              onChange={(prevKnowledge) => {
                dispatch(DashboardFiltersInputKnowledgeSet({
                  value: prevKnowledge ? Number.parseInt(prevKnowledge.value) : undefined,
                  label: prevKnowledge ? prevKnowledge.label : ""
                }));
              }}
              onMouseOver={onMouseOver}
              isClearable
              searchable={false}
              currentToolTip={currentToolTip}
              style={{minWidth:"204px"}}
              whiteBackground={false}
            />
          </div>
        </div>
        <div>
            {recommendations && recommendations[USER_RECOMMENDATION] && !isInSearchMode(searchInput, filteredSubject.label, inputKnowledge.value, inputAge.value) &&
            <div id="recommendations_lessons" className={styles.category}>
              <div className={styles.carouselWrapper}>
                {allLessonsForRecommendation && allLessonsForRecommendation.length > 0 &&
                <Carousel scrollSpeed={scrollSpeed} headerStyle={headerStyle} header={intl.formatMessage({id: "Dashboard.recommendations"})}>
                    <div className={styles.lessonWrapper}>
                        {
                            allLessonsForRecommendation
                                // if we filter all lessons without a given recommendation order
                                .filter((lesson) => recommendations[USER_RECOMMENDATION].courseOrder[lesson.name] !== undefined)
                                .sort((lessonA, lessonB) => {
                                    return recommendations[USER_RECOMMENDATION].courseOrder[lessonA.name] - recommendations[USER_RECOMMENDATION].courseOrder[lessonB.name];
                                })
                                .map((lesson) =>
                                    <ContentTileWrapper
                                        key={lesson.name}
                                        lessonId={lesson.id || ""}
                                        onClick={(event) => onClick(event, lesson.id, "recommendations_lessons")}
                                        href={`${location}content-viewer?content-id=${lesson.id}`}
                                    />)
                        }
                    </div>
                </Carousel>
                }
              </div>
            </div>
            }
          {isInSearchMode(searchInput, filteredSubject.label, inputKnowledge.value, inputAge.value) && searchResult && searchResult.length > 0 &&
            <>
              <div className={styles.carouselWrapper}>
                <Carousel scrollSpeed={scrollSpeed} headerStyle={headerStyle} header={intl.formatMessage({ id: "Dashboard.SearchResults" })}>
                  <div className={styles.lessonWrapper}>
                    {searchResult?.map((lesson) =>
                      <ContentTileWrapper
                        key={lesson.name}
                        lessonId={lesson.id || ""}
                        onClick={(event) => onClick(event, lesson.id, lesson.subject)}
                        href={`${location}content-viewer?content-id=${lesson.id}`}
                        workshop={workshop(lesson.id, externalWorkshops)}
                      />)
                    }
                  </div>
                </Carousel>
              </div>
              {searchResult.length === 0 &&
                <div className={styles.resultsTitle}>
                  {intl.formatMessage({ id: "Dashboard.ResultsFeedback" })}
                </div>
              }
            </>
          }
        </div>
        {!isInSearchMode(searchInput, filteredSubject.label, inputKnowledge.value, inputAge.value) &&
          <div id="new_lessons" className={styles.category}>
            <div className={styles.carouselWrapper}>
              {newestLessons && newestLessons.length > 0 &&
                <Carousel scrollSpeed={scrollSpeed} headerStyle={headerStyle} header={intl.formatMessage({ id: "Dashboard.newLessons" })}>
                  <div className={styles.lessonWrapper}>
                    {
                     newestLessons.map((lesson) =>
                        <ContentTileWrapper
                          key={lesson.name}
                          lessonId={lesson.id || ""}
                          onClick={(event) => onClick(event, lesson.id, "new_lessons")}
                          href={`${location}content-viewer?content-id=${lesson.id}`}
                        />)
                    }
                  </div>
                </Carousel>
              }
            </div>
          </div>
        }
        {!isInSearchMode(searchInput, filteredSubject.label, inputKnowledge.value, inputAge.value) && originalLessons && originalLessons.length > 0 &&
          internalOriginalContent && internalOriginalContent.length > 0 &&
          <div id="original_lessons" className={styles.category}>
            <div className={styles.carouselWrapper}>
              <Carousel scrollSpeed={scrollSpeed} headerStyle={headerStyle} header={intl.formatMessage({ id: "Dashboard.originalLessons" })}>
                <div className={styles.lessonWrapper}>
                  {
                    originalLessons.map((lesson) =>
                      <ContentTileWrapper
                        key={lesson.name}
                        lessonId={lesson.id || ""}
                        onClick={(event) => onClick(event, lesson.id, "original_lessons")}
                        href={`${location}content-viewer?content-id=${lesson.id}`}
                      />)
                  }
                  {
                    internalOriginalContent.map((internalOriginal) =>
                      <ContentTileWrapper
                        key={internalOriginal.name}
                        lessonId={""}
                        internalOriginal={internalOriginal}
                      />
                    )
                  }
                </div>
              </Carousel>
            </div>
          </div>
        }
        {!isInSearchMode(searchInput, filteredSubject.label, inputKnowledge.value, inputAge.value) && initialized && loggedIn && startedCourses && startedCourses.length > 0 &&
          <div id="started_courses" className={styles.category}>
            <div className={styles.carouselWrapper}>
              <Carousel scrollSpeed={scrollSpeed} headerStyle={headerStyle} header={intl.formatMessage({id: "LearningOffers.continue"})}>
                <div className={styles.lessonWrapper}>
                  {
                    startedCourses.map((lesson) =>
                    <ContentTileWrapper
                      key={lesson.name}
                      lessonId={lesson.id || ""}
                      onClick={(event) => onClick(event, lesson.id, "started_courses")}
                      href={`${location}content-viewer?content-id=${lesson.id}`}
                      workshop={workshop(lesson.id, externalWorkshops)}
                    />
                    )
                  }
                </div>
              </Carousel>
            </div>
          </div>
        }
        {!isInSearchMode(searchInput, filteredSubject.label, inputKnowledge.value, inputAge.value) && externalWorkshops && externalWorkshops.length > 0 &&
          <div id="external_workshops" className={styles.category}>
            <div className={styles.carouselWrapper}>
              <Carousel scrollSpeed={scrollSpeed} headerStyle={headerStyle} header={intl.formatMessage({ id: "Dashboard.externalWorkshops" })}>
                <div className={styles.lessonWrapper}>
                  {
                    externalWorkshops.map((lesson) =>
                      <ContentTileWrapper
                        key={lesson.name}
                        lessonId={lesson.id || ""}
                        onClick={(event) => onClick(event, lesson.id, "external_workshops")}
                        href={`${location}content-viewer?content-id=${lesson.id}`}
                        workshop={true}
                      />)
                  }
                </div>
              </Carousel>
            </div>
          </div>
        }
        {!isInSearchMode(searchInput, filteredSubject.label, inputKnowledge.value, inputAge.value) && subjects && subjects
            .filter((subject) => {
              if (recommendations) {
                // if recommendations are available we filter all subjects without a given recommendation order
                return recommendations[subject.name] !== undefined && recommendations[subject.name].subjectOrder !== undefined;
              } else {
                // if no recommendations are available we do not filter at all
                return true;
              }
            })
            .sort((subjectA, subjectB) => {
              if (recommendations) {
                // if recommendations are available we sort all subjects by the given recommendation order
                return recommendations[subjectA.name].subjectOrder - recommendations[subjectB.name].subjectOrder;
              } else {
                // if no recommendations are available we sort by name
                return subjectA.name.localeCompare(subjectB.name);
              }
            })
            .map((subject) =>
          subject.name &&
          <div className={styles.subject} id={subject.id} key={subject.name}>
            <div className={styles.carouselWrapper}>
              <Carousel scrollSpeed={scrollSpeed} headerStyle={headerStyle} header={subject.name}>
                <div className={styles.lessonWrapper}>
                  <ContentTileSubjectGroup subjectId={subject.id || ""}
                                           subjectName={subject.name || ""} />
                </div>
              </Carousel>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
