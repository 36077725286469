import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "./store";
import { Sender } from "../components/Mia/MiaContent/Chat/Chat";

const sliceName = 'miaChat';

export interface ChatMessage {
  sender: Sender;
  output: string;
}

export interface MiaChatHistory {
  miaDashboardChatHistory: ChatMessage[],
  miaCourseChatHistory: ChatMessage[]
}

interface MiaChatState {
  miaChatHistory : MiaChatHistory,
}

/**
 * The initial state of the miaChat state
 */
const initialState: MiaChatState = {
  miaChatHistory : {
    miaDashboardChatHistory: [],
    miaCourseChatHistory: []
  }
}

/**
 * The Redux slice handling actions on the MiaChat slice.
 */
const miaChatSlice = createSlice({
  'name': sliceName,
  'initialState': initialState,
  'reducers': {
    // set the miaChat action
    miaDashboardChatHistorySet(state: MiaChatState, action: PayloadAction<ChatMessage[]>) {
      state.miaChatHistory.miaDashboardChatHistory = action.payload;
    },
    miaCourseChatHistorySet(state: MiaChatState, action: PayloadAction<ChatMessage[]>) {
      state.miaChatHistory.miaCourseChatHistory = action.payload;
    }
  }
})

/**
 * To be used by the Redux storage to set up the miaChat slice.
 */
export const miaChatReducer = miaChatSlice.reducer;

export const {
  miaDashboardChatHistorySet, miaCourseChatHistorySet
} = miaChatSlice.actions;

/**
 * Selector to get the input out of the MiaChat state
 * @param state
 */
export const selectMiaChat = (state: RootState) => state.miaChat;
